import React from "react";

import { AuthProvider } from './auth';
import { NotificationProvider } from "./notification";


// export const Hooks: React.FC = ({ children }: any) => {
//   return (
//     <AuthProvider>
//       {children}
//     </AuthProvider>
//   );
// };


export function Hooks({ children }: any) {
  return (
    <AuthProvider>
      <NotificationProvider>
        {children}
      </NotificationProvider>
    </AuthProvider>
  )
}