import { Outlet } from "react-router-dom";

import Header from '../components/Header';


export function Layout() {
  return (
    <div className='mb-48'>
      <Header />
      <div className='mx-auto max-w-7xl px-4 sm:px-6 mt-4'>
        <Outlet />      
      </div>
    </div>
  );
}

