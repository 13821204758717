// import { Fragment, useState } from 'react'
// import { Combobox, Dialog, Transition } from '@headlessui/react'
// import { MagnifyingGlassIcon } from '@heroicons/react/20/solid'
// import {
//   CalendarIcon,
//   CodeBracketIcon,
//   DocumentIcon,
//   ExclamationCircleIcon,
//   LinkIcon,
//   PencilSquareIcon,
//   PhotoIcon,
//   TableCellsIcon,
//   VideoCameraIcon,
//   ViewColumnsIcon,
//   Bars4Icon,
// } from '@heroicons/react/24/outline'




// interface IItem {
//   id: number;
//   name: string;
//   description: string;
//   url: string;
//   color: string;
//   icon: any;
//   active: boolean;
// }

// const items = [
//   {
//     id: 1,
//     name: 'Text',
//     description: 'Add freeform text with basic formatting options.',
//     url: '#',
//     color: 'bg-indigo-500',
//     icon: PencilSquareIcon,
//     active: false,
//   },
//   // More items...
// ] as IItem[]

// function classNameNames(...classNamees: string[]): string {
//   return classNamees.filter(Boolean).join(' ')
// }

// function Search() {
//   const [query, setQuery] = useState('')

//   const [open, setOpen] = useState(true)

//   const filteredItems =
//     query === ''
//       ? []
//       : items.filter((item) => {
//           return item.name.toLowerCase().includes(query.toLowerCase())
//         })
        

//   return (
//     <Transition.Root show={open} as={Fragment} afterLeave={() => setQuery('')} appear>
//       <Dialog as="div" classNameName="relative z-10" onClose={setOpen}>
//         <Transition.Child
//           as={Fragment}
//           enter="ease-out duration-300"
//           enterFrom="opacity-0"
//           enterTo="opacity-100"
//           leave="ease-in duration-200"
//           leaveFrom="opacity-100"
//           leaveTo="opacity-0"
//         >
//           <div classNameName="fixed inset-0 bg-gray-500 bg-opacity-25 transition-opacity" />
//         </Transition.Child>

//         <div classNameName="fixed inset-0 z-10 overflow-y-auto p-4 sm:p-6 md:p-20">
//           <Transition.Child
//             as={Fragment}
//             enter="ease-out duration-300"
//             enterFrom="opacity-0 scale-95"
//             enterTo="opacity-100 scale-100"
//             leave="ease-in duration-200"
//             leaveFrom="opacity-100 scale-100"
//             leaveTo="opacity-0 scale-95"
//           >
//             <Dialog.Panel classNameName="mx-auto max-w-xl transform divide-y divide-gray-100 overflow-hidden rounded-xl bg-white shadow-2xl ring-1 ring-black ring-opacity-5 transition-all">
//               <Combobox onChange={(item: IItem) => {
//                 // 
//                 console.log(item);
//               }}>
//                 <div classNameName="relative">
//                   <MagnifyingGlassIcon
//                     classNameName="pointer-events-none absolute top-3.5 left-4 h-5 w-5 text-gray-400"
//                     aria-hidden="true"
//                   />
//                   <Combobox.Input
//                     classNameName="h-12 w-full border-0 bg-transparent pl-11 pr-4 text-gray-800 placeholder-gray-400 focus:ring-0 sm:text-sm"
//                     placeholder="Search..."
//                     onChange={(event) => setQuery(event.target.value)}
//                   />
//                 </div>

//                 {filteredItems.length > 0 && (
//                   <Combobox.Options static classNameName="max-h-96 scroll-py-3 overflow-y-auto p-3">
//                     {filteredItems.map((item) => (
//                       <Combobox.Option
//                         key={item.id}
//                         value={item}
//                         classNameName={({ active }) =>
//                           classNameNames('flex cursor-default select-none rounded-xl p-3', active ? 'bg-gray-100' : '')
//                         }
//                       >
//                         {({ active }) => (
//                           <>
//                             <div
//                               classNameName={classNameNames(
//                                 'flex h-10 w-10 flex-none items-center justify-center rounded-lg',
//                                 item.color
//                               )}
//                             >
//                               <item.icon classNameName="h-6 w-6 text-white" aria-hidden="true" />
//                             </div>
//                             <div classNameName="ml-4 flex-auto">
//                               <p
//                                 classNameName={classNameNames(
//                                   'text-sm font-medium',
//                                   active ? 'text-gray-900' : 'text-gray-700'
//                                 )}
//                               >
//                                 {item.name}
//                               </p>
//                               <p classNameName={classNameNames('text-sm', active ? 'text-gray-700' : 'text-gray-500')}>
//                                 {item.description}
//                               </p>
//                             </div>
//                           </>
//                         )}
//                       </Combobox.Option>
//                     ))}
//                   </Combobox.Options>
//                 )}

//                 {query !== '' && filteredItems.length === 0 && (
//                   <div classNameName="py-14 px-6 text-center text-sm sm:px-14">
//                     <ExclamationCircleIcon
//                       type="outline"
//                       name="exclamation-circle"
//                       classNameName="mx-auto h-6 w-6 text-gray-400"
//                     />
//                     <p className="mt-4 font-semibold text-gray-900">No results found</p>
//                     <p className="mt-2 text-gray-500">No components found for this search term. Please try again.</p>
//                   </div>
//                 )}
//               </Combobox>
//             </Dialog.Panel>
//           </Transition.Child>
//         </div>
//       </Dialog>
//     </Transition.Root>
//   )
// }

interface IProps {
    value: string;
    onChange: (text: string) => void;
}

function Search({ value, onChange }: IProps) {
  return (
    <form className="mx-auto max-w-xl px-4 sm:px-6 mb-6">
            <label className="relative block">
                <span className="absolute inset-y-0 left-0 flex items-center pl-3 z-0">
                    <svg className="h-5 w-5 fill-black" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="30"
                        height="30" viewBox="0 0 30 30">
                        <path
                            d="M 13 3 C 7.4889971 3 3 7.4889971 3 13 C 3 18.511003 7.4889971 23 13 23 C 15.396508 23 17.597385 22.148986 19.322266 20.736328 L 25.292969 26.707031 A 1.0001 1.0001 0 1 0 26.707031 25.292969 L 20.736328 19.322266 C 22.148986 17.597385 23 15.396508 23 13 C 23 7.4889971 18.511003 3 13 3 z M 13 5 C 17.430123 5 21 8.5698774 21 13 C 21 17.430123 17.430123 21 13 21 C 8.5698774 21 5 17.430123 5 13 C 5 8.5698774 8.5698774 5 13 5 z">
                        </path>
                    </svg>
                </span>
                <input
                    value={value}
                    onChange={(input) => {
                        onChange(input.target.value)
                    }}
                    className="w-full bg-white placeholder:font-italitc border border-slate-300 rounded-full py-2 pl-10 pr-4 focus:outline-none text-sm"
                    placeholder="Pesquise tipos de conteúdo como Facebook Ads, Ideias de Blog..." type="text" />
            </label>
        </form>
  );
}


export default Search;