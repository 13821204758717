import React, {
  createContext,
  useCallback,
  useState,
  useContext,
  useEffect,
} from "react";

import { api, CommonHeaderProperties } from "../services/api";

interface Account {
  id: string;
  name: string;
  email: string;
  wordsUsage: number;
  plan: {
    id: string;
    name: string;
    wordsLimit: number;
  };
}

interface AuthState {
  token: string;
  account: Account;
}

interface SignInCredentials {
  email: string;
  password: string;
}

interface AuthContextData {
  account: Account;
  signIn(credentials: SignInCredentials): Promise<void>;
  signInWithToken(token: string): Promise<void>;
  signOut(): void;
  refresh: () => void;
}

const AuthContext = createContext<AuthContextData>({} as AuthContextData);

interface AuthProviderProps {
  children: React.ReactNode;
}

export function AuthProvider({ children }: AuthProviderProps) {
  const [data, setData] = useState<AuthState>(() => {
    const token = localStorage.getItem("@stricv3:token");
    const account = localStorage.getItem("@stricv3:account") as string;

    if (token && account) {
      const accountData = JSON.parse(account);

      api.defaults.headers = {
        Authorization: `Bearer ${token}`,
        account: accountData.id,
      } as CommonHeaderProperties;

      return { token, account: accountData };
    }

    return {} as AuthState;
  });

  async function getAccount() {
    const token = window.localStorage.getItem("@stricv3:token");
    if (token) {
      try {
        api.defaults.headers = {
          Authorization: `Bearer ${token}`,
        } as CommonHeaderProperties;

        const { data } = await api.get("/accounts");

        setData({ token, account: data.account });
      } catch (err) {
        signOut();
      }
    }
  }

  function refresh() {
    getAccount();
  }

  useEffect(() => {
    getAccount();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (data.account) {
      //@ts-ignore variable from script
      FreshworksWidget("identify", "ticketForm", {
        name: data.account.name,
        email: data.account.email,
      });
      //@ts-ignore variable from script
      FreshworksWidget("disable", "ticketForm", ["email"]);
    }
  }, [data]);

  const signIn = useCallback(async ({ email, password }: SignInCredentials) => {
    const response = await api.post("/accounts/session", {
      email,
      password,
    });

    const { access_token: token } = response.data;

    api.defaults.headers = {
      Authorization: `Bearer ${token}`,
    } as CommonHeaderProperties;

    const { data } = await api.get("/accounts");

    localStorage.setItem("@stricv3:token", token);
    localStorage.setItem("@stricv3:account", JSON.stringify(data.account));

    setData({ token, account: data.account });
  }, []);

  const signInWithToken = useCallback(async (token: string) => {
    api.defaults.headers = {
      Authorization: `Bearer ${token}`,
    } as CommonHeaderProperties;

    const { data } = await api.get("/accounts");

    localStorage.setItem("@stricv3:token", token);
    localStorage.setItem("@stricv3:account", JSON.stringify(data.account));

    setData({ token, account: data.account });
  }, []);

  const signOut = useCallback(() => {
    localStorage.removeItem("@stricv3:token");
    localStorage.removeItem("@stricv3:account");

    setData({} as AuthState);
  }, []);

  return (
    <AuthContext.Provider
      value={{
        account: data.account,
        signIn,
        signOut,
        signInWithToken,
        refresh,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export function useAuth(): AuthContextData {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
}
