import { useEffect, useState, Fragment } from "react";
import { api } from "../services/api";

import { Dialog, Transition } from "@headlessui/react";
import {
  PlusIcon,
  XMarkIcon,
  ClipboardDocumentIcon,
  HandThumbUpIcon,
  HandThumbDownIcon,
} from "@heroicons/react/24/outline";
import {
  HandThumbUpIcon as HandThumbUpIconSolid,
  HandThumbDownIcon as HandThumbDownIconSolid,
} from "@heroicons/react/24/solid";
import { Link } from "react-router-dom";
import { useNotification } from "../hooks/notification";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { useQuery, UseQueryResult } from "react-query";
import Spinner from "../components/Spinner";
import { queryClient } from "../services/queryClient";

interface IOutput {
  id: string;
  output: string;
  date: Date;
  like: boolean | null;
}

interface IContent {
  id: string;
  accountId: string;
  modelId: string;
  input: string;
  words: string;
  totalTokens: number;
  createdAt: Date;
  updatedAt: Date;
  type: string;
  model: {
    id: string;
    name: string;
    description: string;
    category: string;
    logoUrl: string;
  };
  outputs: IOutput[];
}

interface IDataContent {
  total: number;
  page: number;
  totalPage: number;
  contents: IContent[];
}

function removeItemFromArray(arr: any, value: any) {
  var i = 0;
  while (i < arr.length) {
    if (arr[i] === value) {
      arr.splice(i, 1);
    } else {
      ++i;
    }
  }
  return arr;
}

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(" ");
}

export function Projects() {
  const [open, setOpen] = useState(false);

  const { setOpenNotification } = useNotification();

  const [current, setCurrent] = useState<IContent>({} as IContent);

  // Management of Like and Dislike of results. The array is of result IDs
  const [likedResults, setLikedResults] = useState<string[]>([]);
  const [dislikedResults, setDislikedResults] = useState<string[]>([]);

  const [page, setPage] = useState(0);
  const [totalItens, setTotalItens] = useState(1);

  const { data, isError, isFetched }: UseQueryResult<IDataContent, Error> =
    useQuery(
      ["contents", page],
      async () => {
        const response = await api.get(`/contents?page=${page}`);
        setTotalItens(response.data.total);
        return response.data;
      },
      {
        staleTime: 1000 * 1, //1s
      },
    );

  useEffect(() => {
    const prefetchNextPage = async () => {
      // The results of this query will be cached like a normal query
      await queryClient.prefetchQuery(["contents", page + 1], async () => {
        const response = await api.get(`/contents?&page=${page + 1}`);
        return response.data;
      });
    };
    prefetchNextPage();
  }, [page]);

  function handleCurrent(currentContent: IContent) {
    setCurrent(currentContent);
    setOpen(true);
  }

  // whenever an output is liked or disliked
  async function handleLike(type: "like" | "dislike", output: IOutput) {
    // this is for the front end management
    let newLikedArray: string[] = [];
    let newdislikedArray: string[] = [];

    //  this is the value we send to the API
    let newValue = null;

    // if person hit the like button and...
    if (type === "like") {
      // ... it was already liked => we do nothing
      if (likedResults.includes(output.id)) {
        return;
      }

      // ...if it was disliked => we transform into a like
      if (dislikedResults.includes(output.id)) {
        newdislikedArray = removeItemFromArray(dislikedResults, output.id);
        newLikedArray = [...likedResults, output.id];
        newValue = true;
      }

      // ...if it was neither liked nor disliked => we make it a like
      if (
        !likedResults.includes(output.id) &&
        !dislikedResults.includes(output.id)
      ) {
        newLikedArray = [...likedResults, output.id];
        newdislikedArray = dislikedResults; //dislikes stay the same
        newValue = true;
      }
    }

    // if person hit the dislike button and...
    if (type === "dislike") {
      // ... it was already disliked => we do nothing
      if (dislikedResults.includes(output.id)) {
        return;
      }

      // ... it was a like => we transform it into a dislike
      if (likedResults.includes(output.id)) {
        newLikedArray = removeItemFromArray(likedResults, output.id);
        newdislikedArray = [...dislikedResults, output.id];
        newValue = false;
      }

      // ...if it was neither liked nor disliked => we make it a dislike
      if (
        !likedResults.includes(output.id) &&
        !dislikedResults.includes(output.id)
      ) {
        newdislikedArray = [...dislikedResults, output.id];
        newLikedArray = likedResults; // likes stay the same
        newValue = false;
      }
    }

    setLikedResults([...newLikedArray]);
    setDislikedResults([...newdislikedArray]);
    // api call to
    api.post("/contents/like", {
      outputId: output.id,
      like: newValue,
    });
  }

  useEffect(setLikedContent, [data?.contents]);
  // this function is called after data is fetched to fill the liked and disliked arrays
  function setLikedContent() {
    if (data?.contents) {
      let newLikedResults = likedResults;
      let newDislikedResults = dislikedResults;

      for (let i = 0; i < data.contents.length; i++) {
        const content = data.contents[i];

        for (let j = 0; j < content.outputs.length; j++) {
          const output = content.outputs[j];

          if (output.like === true)
            newLikedResults = [...newLikedResults, output.id];

          if (output.like === false)
            newDislikedResults = [...newDislikedResults, output.id];
        }
      }
      setLikedResults(newLikedResults);
      setDislikedResults(newDislikedResults);
    }
  }

  return (
    <>
      <div className="mx-auto py-4 px-4 sm:py-8 sm:px-6 lg:px-8">
        <div className="text-center">
          <p className="mt-1 text-2xl font-display font-bold tracking-tight text-[#1c033e] sm:text-3xl lg:text-4xl">
            Meus Conteúdos
          </p>
          {/* <p className="mx-auto mt-5 max-w-xl text-lg text-gray-500">
            selecionando o tipo de conteúdo nas opções abaixo
          </p> */}
        </div>
      </div>
      {isError && <div>Error ao Carregar</div>}
      {!isFetched && (
        <div className="flex justify-center mt-12">
          <Spinner />
        </div>
      )}
      {data?.contents && data?.contents.length <= 0 && (
        <div className="text-center">
          <svg
            className="mx-auto h-12 w-12 text-gray-400"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            aria-hidden="true"
          >
            <path
              vectorEffect="non-scaling-stroke"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M9 13h6m-3-3v6m-9 1V7a2 2 0 012-2h6l2 2h6a2 2 0 012 2v8a2 2 0 01-2 2H5a2 2 0 01-2-2z"
            />
          </svg>
          <h3 className="mt-2 text-sm font-medium text-gray-900">
            Nenhum conteúdo
          </h3>
          <p className="mt-1 text-sm text-gray-500">
            Comece criando um novo conteúdo.
          </p>
          <div className="mt-6">
            <Link
              to="/"
              className="inline-flex items-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
            >
              <PlusIcon className="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
              Criar novo conteúdo
            </Link>
          </div>
        </div>
      )}

      <ul className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3">
        {data?.contents &&
          data?.contents.map((content) => (
            <li
              key={content.id}
              className="cursor-pointer col-span-1 divide-y divide-gray-200 rounded-lg bg-white shadow"
            >
              <span onClick={() => handleCurrent(content)}>
                <div className="flex w-full items-center justify-between space-x-6 p-6">
                  <img
                    className="h-10 w-10 flex-shrink-0"
                    src={content.model.logoUrl}
                    alt=""
                  />
                  <div className="flex-1 truncate">
                    <div className="flex items-center space-x-3">
                      <h3 className="truncate text-sm font-medium text-gray-900">
                        {content.input}
                      </h3>
                      <span className="inline-block flex-shrink-0 rounded-full bg-gray-100 px-2 py-0.5 text-xs font-medium text-gray-800">
                        {/* {content.content.name} */}
                      </span>
                    </div>
                    <p className="mt-1 truncate text-sm text-gray-500">
                      {content.model.name}
                    </p>
                  </div>
                </div>
                <div></div>
              </span>
            </li>
          ))}
      </ul>

      {/* Pagination */}
      <nav
        className="flex items-center mt-6 justify-between border-t border-gray-200 bg-white px-4 py-3 sm:px-6"
        aria-label="Pagination"
      >
        <div className="hidden sm:block">
          <p className="text-sm text-gray-700">
            Mostrando <span className="font-medium">{page * 12 + 1}</span> a{" "}
            <span className="font-medium">
              {Math.min((page + 1) * 12, totalItens)}
            </span>{" "}
            de <span className="font-medium">{totalItens}</span> resultados
          </p>
        </div>
        <div className="flex flex-1 justify-between sm:justify-end">
          <button
            disabled={page === 0}
            onClick={() => setPage(page - 1)}
            className={classNames(
              "relative inline-flex items-center rounded-md bg-white px-3 py-2 mr-2 text-sm font-semibold  ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus-visible:outline-offset-0",
              page === 0 ? "text-gray-400" : "text-gray-900",
            )}
          >
            Anterior
          </button>
          <button
            disabled={page === data?.totalPage}
            onClick={() => setPage(page + 1)}
            className={classNames(
              "relative inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold  ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus-visible:outline-offset-0",
              page === data?.totalPage ? "text-gray-400" : "text-gray-900",
            )}
          >
            Próximo
          </button>
        </div>
      </nav>

      {/* Modal  */}
      <Transition.Root show={open} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={setOpen}>
          <div className="fixed inset-0" />

          <div className="fixed inset-0 overflow-hidden">
            <div className="absolute inset-0 overflow-hidden">
              <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
                <Transition.Child
                  as={Fragment}
                  enter="transform transition ease-in-out duration-500 sm:duration-700"
                  enterFrom="translate-x-full"
                  enterTo="translate-x-0"
                  leave="transform transition ease-in-out duration-500 sm:duration-700"
                  leaveFrom="translate-x-0"
                  leaveTo="translate-x-full"
                >
                  <Dialog.Panel className="pointer-events-auto w-screen max-w-2xl">
                    <div className="flex h-full flex-col overflow-y-scroll bg-white shadow-xl">
                      <div className="px-4 py-6 sm:px-6">
                        <div className="flex items-start justify-between">
                          <Dialog.Title className="text-lg font-medium text-gray-900">
                            {/*  */}
                          </Dialog.Title>
                          <div className="ml-3 flex h-7 items-center">
                            <button
                              type="button"
                              className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:ring-2 focus:ring-indigo-500"
                              onClick={() => setOpen(false)}
                            >
                              <span className="sr-only">Close panel</span>
                              <XMarkIcon
                                className="h-6 w-6"
                                aria-hidden="true"
                              />
                            </button>
                          </div>
                        </div>
                      </div>
                      {/* Main */}
                      <div className="divide-y divide-gray-200">
                        <div className="pb-6">
                          <div className="lg:-mt-15 -mt-12 flow-root px-4 sm:-mt-8 sm:flex sm:items-end sm:px-6">
                            <div></div>
                            <div className="mt-6 sm:ml-6 sm:flex-1">
                              <div>
                                <div className="flex items-center">
                                  <h3 className="text-xl font-bold text-gray-900 sm:text-2xl">
                                    Resultados
                                  </h3>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="px-4 py-5 sm:px-0 sm:py-0">
                          <dl className="space-y-8 sm:space-y-0 sm:divide-y sm:divide-gray-200 pb-16">
                            <div className="sm:flex sm:px-6 sm:py-5">
                              {/* <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0 lg:w-48">
                              Input
                            </dt> */}
                              <dd className="mt-1 font-semibold text-sm text-gray-900 sm:col-span-2 sm:mt-0 sm:ml-6">
                                <p>{current.input}</p>
                              </dd>
                            </div>

                            {current &&
                              current?.outputs?.map((output) => (
                                <div
                                  key={output.id}
                                  className="sm:flex sm:px-6 sm:py-5"
                                >
                                  {/* Text */}
                                  {current.type === "text" && (
                                    <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0 sm:ml-6">
                                      {output.output}
                                      <div className="flex mt-2">
                                        <div className="mr-6">
                                          <CopyToClipboard
                                            text={output.output}
                                            onCopy={() => {
                                              setOpenNotification({
                                                type: "success",
                                                title: "Texto copiado",
                                                notification:
                                                  "Cole o conteúdo copiado na sua plataforma de textos.",
                                              });
                                            }}
                                          >
                                            <ClipboardDocumentIcon className="text-gray-500 w-5 h-5 cursor-pointer" />
                                          </CopyToClipboard>
                                        </div>
                                        <div
                                          className="mr-2"
                                          onClick={() =>
                                            handleLike("like", output)
                                          }
                                        >
                                          {likedResults.includes(output.id) ? (
                                            <HandThumbUpIconSolid className="text-green-700 w-5 h-5 cursor-pointer" />
                                          ) : (
                                            <HandThumbUpIcon className="text-gray-500 w-5 h-5 cursor-pointer" />
                                          )}
                                        </div>
                                        <div
                                          onClick={() =>
                                            handleLike("dislike", output)
                                          }
                                        >
                                          {dislikedResults.includes(
                                            output.id,
                                          ) ? (
                                            <HandThumbDownIconSolid className="text-red-700 w-5 h-5 cursor-pointer" />
                                          ) : (
                                            <HandThumbDownIcon className="text-gray-500 w-5 h-5 cursor-pointer" />
                                          )}
                                        </div>
                                      </div>
                                    </dd>
                                  )}

                                  {/* Images */}
                                  {current.type === "image" && (
                                    <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0 sm:ml-6">
                                      <img
                                        src={output.output}
                                        className="h-96 w-96"
                                      />
                                      <div className="flex mt-2">
                                        <a
                                          className="inline-flex mr-4 items-center rounded-md border border-transparent bg-indigo-600 px-3 py-1 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                          href={output.output}
                                          target="_blank"
                                        >
                                          Baixar imagem
                                        </a>

                                        <div
                                          className="mr-2"
                                          onClick={() =>
                                            handleLike("like", output)
                                          }
                                        >
                                          {likedResults.includes(output.id) ? (
                                            <HandThumbUpIconSolid className="text-green-700 w-5 h-5 cursor-pointer" />
                                          ) : (
                                            <HandThumbUpIcon className="text-gray-500 w-5 h-5 cursor-pointer" />
                                          )}
                                        </div>
                                        <div
                                          onClick={() =>
                                            handleLike("dislike", output)
                                          }
                                        >
                                          {dislikedResults.includes(
                                            output.id,
                                          ) ? (
                                            <HandThumbDownIconSolid className="text-red-700 w-5 h-5 cursor-pointer" />
                                          ) : (
                                            <HandThumbDownIcon className="text-gray-500 w-5 h-5 cursor-pointer" />
                                          )}
                                        </div>
                                      </div>
                                    </dd>
                                  )}
                                </div>
                              ))}
                          </dl>
                        </div>
                      </div>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
}
