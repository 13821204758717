import React from "react";
import {
  BrowserRouter,
  Routes,
  Route,
  useLocation,
  Navigate,
} from "react-router-dom";
import { useAuth } from "../hooks/auth";
import { Account } from "../pages/Account";
import { Create } from "../pages/Create";
import { Dashboard } from "../pages/Dashboard";
import { Forgot } from "../pages/Forgot";
import { History } from "../pages/History";

import { Layout } from "../pages/Layout";
import { Logout } from "../pages/Logout";
import { Price } from "../pages/Price";
import { Projects } from "../pages/Projects";
import { Register } from "../pages/Register";
import { SignIn } from "../pages/SignIn";
import { Validate } from "../pages/Validate";

function RequireAuth({ children }: { children: JSX.Element }) {
  let { account } = useAuth();
  let location = useLocation();

  if (!account) {
    return <Navigate to="/signin" state={{ from: location }} replace />;
  }

  return children;
}

const routes: React.FC = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/signin" element={<SignIn />} />
        <Route path="/register" element={<Register />} />
        <Route path="/forgot" element={<Forgot />} />
        <Route path="/validate" element={<Validate />} />
        <Route
          path="/"
          element={
            <RequireAuth>
              <Layout />
            </RequireAuth>
          }
        >
          <Route index element={<Dashboard />} />

          <Route path="/create/:category" element={<Create />} />
          <Route path="/projects" element={<Projects />} />
          <Route path="/history" element={<History />} />

          <Route path="/price" element={<Price />} />
          <Route path="/account" element={<Account />} />

          <Route path="/signout" element={<Logout />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

export default routes;
