import { Fragment, useEffect, useState } from "react";
import { Menu, Transition } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import { StarIcon } from "@heroicons/react/24/outline";
import { Link } from "react-router-dom";
import { useAuth } from "../hooks/auth";
import { getMenuName } from "../services/utils";

function classNames(...classes: string[]): string {
  return classes.filter(Boolean).join(" ");
}

function MyAccount() {
  const { account } = useAuth();
  const [percent, setPercent] = useState("0%");

  useEffect(() => {
    const total = account.plan.wordsLimit;
    const usage = account.wordsUsage;
    const percentUsage = (usage / total) * 100;

    if (percentUsage > 100) {
      setPercent(`100%`);
    } else {
      setPercent(`${percentUsage}%`);
    }
  }, [account.wordsUsage, account.plan.wordsLimit]);

  return (
    <Menu as="div" className="relative inline-block text-left">
      <div>
        <Menu.Button className="flex items-center rounded-full text-black hover:text-gray-600 focus:outline-none">
          <span className="sr-only">Open options</span>
          <div className="flex items-center">
            <span className="inline-block h-9 w-9 overflow-hidden rounded-full bg-gray-100">
              <svg
                className="h-full w-full text-gray-300"
                fill="currentColor"
                viewBox="0 0 24 24"
              >
                <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
              </svg>
            </span>
            <div className="text-left">
              <div className="flex items-center">
                <span className="pl-4 pr-4">{getMenuName(account.name)}</span>
                <ChevronDownIcon className="h-5 w-5" aria-hidden="true" />
              </div>
              <span className="text-xs pl-4 truncate">Configurações</span>
            </div>
          </div>
        </Menu.Button>
      </div>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
          <div className="py-1">
            <Menu.Item>
              {({ active }) => (
                <Link
                  to="/account"
                  className={classNames(
                    active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                    "block px-4 py-2 text-sm",
                  )}
                >
                  Minha conta
                </Link>
              )}
            </Menu.Item>

            <Menu.Item>
              {({ active }) => (
                <Link
                  to="/signout"
                  className={classNames(
                    active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                    "block px-4 py-2 text-sm",
                  )}
                >
                  Sair
                </Link>
              )}
            </Menu.Item>
          </div>
          <div className="py-1 border-t-2">
            <div className="px-4 py-2">
              <span className="flex items-center mb-2">
                <span className="text-sm leading-6 font-medium">
                  Plano {account.plan.name}
                </span>
              </span>
              <div className="bg-gray-200 w-full h-2 rounded-sm mt-2 mb-1">
                <div
                  className="bg-gray-500 h-2 rounded-sm"
                  style={{ width: percent }}
                ></div>
              </div>
              <span className="text-xs mb-3 block">
                {account.wordsUsage} de {account.plan.wordsLimit} palavras
                usadas.
              </span>
              <Menu.Item>
                {() => (
                  <Link to="/price">
                    <span className="inline-flex items-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                      Explorar planos
                    </span>
                  </Link>
                )}
              </Menu.Item>
            </div>
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
}

export default MyAccount;
