import { CheckIcon, ShoppingCartIcon } from "@heroicons/react/24/outline";
import { Fragment, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
// import { Loading } from "../components/Loadind";
// import { Link } from "react-router-dom";
import { api } from "../services/api";
import { Loading } from "../components/Loading";
import { useAuth } from "../hooks/auth";

const pricing = {
  tiers: [
    {
      id: "price_1MbAebAchoCNzDWqYIzdjTOC",
      title: "Personal",
      price: "49",
      frequency: "/mês",
      description: "",
      features: [
        "20.000 palavras/mês",
        "20+ Modelos disponíveis",
        "Suporte Ativo",
        "Até 3 conteúdos por vez",
      ],
      cta: "Escolher plano",
      mostPopular: false,
    },
    {
      id: "price_1MbAfJAchoCNzDWqoKd9RuFo",
      title: "Pro",
      price: "89",
      frequency: "/mês",
      description: "",
      features: [
        "50.000 palavras/mês",
        "Todos modelos disponíveis",
        "Até 5 conteúdos por vez",
        "Suporte Prioritário",
        "IA que se adapta",
        "Verificação de qualidade",
        "Crie seus próprios modelos",
        "Solicite um tipo de modelo",
      ],
      cta: "Escolher plano",
      mostPopular: true,
    },
    {
      id: "price_1MbAftAchoCNzDWqe7hGsQpk",
      title: "Advanced",
      price: "249",
      frequency: "/mês",
      description: "",
      features: [
        "250.000 palavras/mês",
        "Todos modelos disponíveis",
        "Até 5 conteúdos por vez",
        "Suporte Prioritário",
        "IA que se adapta",
        "Verificação de qualidade",
        "Crie seus próprios modelos",
        "Solicite um tipo de modelo",
      ],
      cta: "Escolher plano",
      mostPopular: false,
    },
  ],
};

const faqs = [
  {
    id: 1,
    question: 'O que significa as "palavras" na meu plano?',
    answer:
      "Para cálculo do uso da plataforma, são consideradas as palavras da descrição (escritas por você) e as palavras do conteúdo gerado (escritas pela IA).",
  },
  {
    id: 2,
    question: "Posso usar o texto gerado para uso comercial?",
    answer: "Sim, você possui todo o conteúdo gerado por você na Copyo.",
  },
  {
    id: 3,
    question: "O texto gerado é original e livre de plágio?",
    answer:
      "O Copyo gera um texto original com base em seus critérios de entrada.",
  },
];

function classNames(...classes: string[]): string {
  return classes.filter(Boolean).join(" ");
}

export function Price() {
  const [loading, setLoading] = useState(false);
  const { account } = useAuth();

  async function handleClickPrice(priceId: string) {
    setLoading(true);
    try {
      if (account.plan.name === "Free") {
        const { data } = await api.post("/stripe/create-checkout-session", {
          priceId,
        });
        setLoading(false);
        if (data && data.url) {
          window.location.href = data.url;
        }
      } else {
        const { data } = await api.post("/stripe/create-portal-session");

        if (data && data.url) {
          window.location.href = data.url;
        }
      }
    } catch (err) {
      setLoading(false);
    }
  }
  return (
    <>
      <div className="mx-auto py-4 px-4 sm:py-8 sm:px-6 lg:px-8">
        <div className="text-center">
          <p className="mt-1 text-2xl font-display font-bold tracking-tight text-[#1c033e] sm:text-3xl lg:text-4xl">
            Planos e preços
          </p>
          <p className="mx-auto mt-5 max-w-xl text-lg text-gray-500">
            Mais de 1.000 usuários confiam na Copyo com seu conteúdo.
          </p>
        </div>
      </div>

      <div className="mx-auto py-4 px-4 md:w-12/12">
        <div className="mx-auto max-w-7xl   sm:px-6 lg:px-8">
          {/* Tiers */}
          <div className="mt-24 space-y-12 lg:grid lg:grid-cols-3 lg:gap-x-8 lg:space-y-0">
            {pricing.tiers.map((tier) => (
              <div
                key={tier.title}
                className="relative flex flex-col rounded-2xl border border-gray-200 bg-white p-8 shadow-sm"
              >
                <div className="flex-1">
                  <h3 className="text-xl font-display font-semibold text-gray-900">
                    {tier.title}
                  </h3>
                  {tier.mostPopular ? (
                    <p className="absolute top-0 -translate-y-1/2 transform rounded-full bg-indigo-500 py-1.5 px-4 text-sm font-semibold text-white">
                      Mais Popular
                    </p>
                  ) : null}
                  <p className="mt-4 flex items-baseline text-gray-900">
                    <span className="text-3xl font-bold tracking-tight">
                      R$ {tier.price}
                    </span>
                    <span className="ml-1 text-xl font-semibold">
                      {tier.frequency}
                    </span>
                  </p>
                  <p className="mt-6 text-gray-500">{tier.description}</p>

                  {/* Feature list */}
                  <ul className="mt-12 space-y-4">
                    {tier.features.map((feature) => (
                      <li key={feature} className="flex">
                        <CheckIcon
                          className="h-6 w-6 flex-shrink-0 text-indigo-500"
                          aria-hidden="true"
                        />
                        <span className="ml-3 text-gray-500 text-md">
                          {feature}
                        </span>
                      </li>
                    ))}
                  </ul>
                </div>
                {tier.cta && (
                  <button
                    onClick={() => handleClickPrice(tier.id)}
                    type="button"
                    className={classNames(
                      tier.mostPopular
                        ? "bg-indigo-500 text-white hover:bg-indigo-600"
                        : "bg-indigo-50 text-indigo-700 hover:bg-indigo-100",
                      "mt-8 block w-full py-3 px-6 border border-transparent rounded-md text-center font-medium",
                    )}
                  >
                    {tier.cta}
                  </button>
                )}
              </div>
            ))}
          </div>
        </div>

        {/* faq */}
        <div className=" mt-8">
          <div className="mx-auto max-w-7xl py-16 px-4 sm:px-6 lg:py-20 lg:px-8">
            <div className="lg:grid lg:grid-cols-3 lg:gap-8">
              <div>
                <h2 className="text-3xl font-bold font-display tracking-tight text-gray-900">
                  Perguntas frequentes
                </h2>
                <p className="mt-4 text-lg text-gray-500">
                  Não consegue encontrar a resposta que procura? Entre em
                  contato com nossa equipe de suporte ao cliente.
                </p>
              </div>
              <div className="mt-12 lg:col-span-2 lg:mt-0">
                <dl className="space-y-12">
                  {faqs.map((faq) => (
                    <div key={faq.id}>
                      <dt className="text-lg font-medium leading-6 text-gray-900">
                        {faq.question}
                      </dt>
                      <dd className="mt-2 text-base text-gray-500">
                        {faq.answer}
                      </dd>
                    </div>
                  ))}
                </dl>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Modal */}
      <Transition.Root show={loading} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={() => {}}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6">
                  <div>
                    <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-green-100">
                      <ShoppingCartIcon
                        className="h-6 w-6 text-green-600"
                        aria-hidden="true"
                      />
                    </div>
                    <div className="mt-3 text-center sm:mt-5">
                      {/* <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900">
                      Aguarde
                    </Dialog.Title> */}
                      <div className="mt-2 flex justify-center items-center">
                        <p className="text-lg font-medium leading-6 text-gray-500">
                          Carregando compra segura
                        </p>
                        <Loading classes="ml-3" />
                      </div>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
}
