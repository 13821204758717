import { useEffect, useRef, useState, Fragment } from "react";
import { Link, useParams } from "react-router-dom";

import { Dialog, Transition } from "@headlessui/react";
import {
  ArrowLeftIcon,
  ClipboardDocumentIcon,
  QuestionMarkCircleIcon,
  HandThumbUpIcon,
  HandThumbDownIcon,
  MinusIcon,
  PlusIcon,
  Battery0Icon,
} from "@heroicons/react/24/outline";

import {
  HandThumbUpIcon as HandThumbUpIconSolid,
  HandThumbDownIcon as HandThumbDownIconSolid,
} from "@heroicons/react/24/solid";
import { api, parseError } from "../services/api";
import { Loading } from "../components/Loading";
import CopyToClipboard from "react-copy-to-clipboard";
import { useNotification } from "../hooks/notification";
import { useAuth } from "../hooks/auth";
import CTAModal from "../components/CTAModal";

function removeItemFromArray(arr: any, value: any) {
  var i = 0;
  while (i < arr.length) {
    if (arr[i] === value) {
      arr.splice(i, 1);
    } else {
      ++i;
    }
  }
  return arr;
}

function classNames(...classes: string[]): string {
  return classes.filter(Boolean).join(" ");
}

interface IModel {
  id: string;
  name: string;
  description: string;
  category: string;
  logoUrl: string;
  placeholder: string;
  example: string;
  tips: string;
}

interface IOutputs {
  id: string;
  output: string;
  date: string;
  like: boolean | null;
}

interface IResult {
  content: {
    input: string;
    totalTokens: number;
    words: number;
    type: string;
  };
  outputs: IOutputs[];
}

export function Create() {
  const { category } = useParams();
  const { account } = useAuth();
  const [loading, setLoading] = useState(false);

  // const navigate = useNavigate();
  const [results, setResults] = useState<IResult>({} as IResult);
  const [input, setInput] = useState<string>("");

  const [result, setResult] = useState(false);

  const [model, setModel] = useState<IModel>({} as IModel);

  const { setOpenNotification } = useNotification();

  const [open, setOpen] = useState(false);
  const cancelButtonRef = useRef(null);

  const [openPlan, setOpenPlan] = useState(false);
  const cancelButtonRefPlano = useRef(null);

  useEffect(() => {
    api
      .get(`/models/details/${category}`)
      .then(({ data }) => {
        setModel(data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [category]);

  async function handleOutputs() {
    if (loading) {
      return;
    }

    if (input.length < 10) {
      setOpenNotification({
        type: "error",
        title: "Descrição muito curta.",
        notification: "Aumente a descrição para gerar seus conteúdos",
      });
      return;
    }

    try {
      setLoading(true);
      const { data } = await api.post("/contents", {
        modelId: category,
        input,
        resultsCount: resultsAmount,
      });
      setResults(data);
      setResult(true);
      setLoading(false);
    } catch (err) {
      setResult(false);
      setResults({} as IResult);
      setLoading(false);

      const error = parseError(err);

      setOpenNotification({
        type: "error",
        title: "Erro ao gerar textos.",
        notification: error.friend,
      });

      if (error.code === "account.limit") {
        setOpenPlan(true);
      }
    }
  }

  // Managing content amount desired by user

  const plan = account.plan.name;
  const CONTENT_MIN = 1;
  const CONTENT_MAX = plan === "Free" ? 2 : plan === "Personal" ? 3 : 5;
  const CONTENT_DEFAULT = 2;
  const [resultsAmount, setResultsAmount] = useState(CONTENT_DEFAULT);

  function handleAmountChange(type: "plus" | "minus") {
    if (type === "plus") {
      const newValue = resultsAmount + 1;
      if (newValue > CONTENT_MAX) {
        return;
      }
      setResultsAmount(newValue);
    }

    if (type === "minus") {
      const newValue = resultsAmount - 1;
      if (newValue < CONTENT_MIN) {
        return;
      }
      setResultsAmount(newValue);
    }
  }

  // Managing likes and dislikes
  const [likedResults, setLikedResults] = useState<string[]>([]);
  const [dislikedResults, setDislikedResults] = useState<string[]>([]);

  async function handleLike(type: "like" | "dislike", output: IOutputs) {
    // this is for the front end management
    let newLikedArray: string[] = [];
    let newdislikedArray: string[] = [];

    //  this is the value we send to the API
    let newValue = null;

    // if person hit the like button and...
    if (type === "like") {
      // ... it was already liked => we do nothing
      if (likedResults.includes(output.id)) {
        return;
      }

      // ...if it was disliked => we transform into a like
      if (dislikedResults.includes(output.id)) {
        newdislikedArray = removeItemFromArray(dislikedResults, output.id);
        newLikedArray = [...likedResults, output.id];
        newValue = true;
      }

      // ...if it was neither liked nor disliked => we make it a like
      if (
        !likedResults.includes(output.id) &&
        !dislikedResults.includes(output.id)
      ) {
        newLikedArray = [...likedResults, output.id];
        newdislikedArray = dislikedResults; //dislikes stay the same
        newValue = true;
      }
    }

    // if person hit the dislike button and...
    if (type === "dislike") {
      // ... it was already disliked => we do nothing
      if (dislikedResults.includes(output.id)) {
        return;
      }

      // ... it was a like => we transform it into a dislike
      if (likedResults.includes(output.id)) {
        newLikedArray = removeItemFromArray(likedResults, output.id);
        newdislikedArray = [...dislikedResults, output.id];
        newValue = false;
      }

      // ...if it was neither liked nor disliked => we make it a dislike
      if (
        !likedResults.includes(output.id) &&
        !dislikedResults.includes(output.id)
      ) {
        newdislikedArray = [...dislikedResults, output.id];
        newLikedArray = likedResults; // likes stay the same
        newValue = false;
      }
    }

    setLikedResults([...newLikedArray]);
    setDislikedResults([...newdislikedArray]);
    // api call to
    api.post("/contents/like", {
      outputId: output.id,
      like: newValue,
    });
  }

  return (
    <>
      <div className="flex justify-center w-full md:w-9/12 mx-auto">
        {!result ? (
          <Link to="/">
            <div className="mr-4 w-12 h-12 bg-white shadow-md rounded-md flex items-center justify-center">
              <ArrowLeftIcon className="pointer-events-none top-3.5 left-4 h-5 w-5 text-black" />
            </div>
          </Link>
        ) : (
          <span
            className="cursor-pointer"
            onClick={() => {
              setResult(false);
              setInput("");
              setResults({} as IResult);
            }}
          >
            <div className="mr-4 w-12 h-12 bg-white shadow-md rounded-md flex items-center justify-center">
              <ArrowLeftIcon className="pointer-events-none top-3.5 left-4 h-5 w-5 text-black" />
            </div>
          </span>
        )}

        <div className="bg-white mx-auto w-full flex-1 shadow-md rounded-md">
          <div className="border-b border-b-gray-200 py-4 px-4 flex">
            <img className="w-8" src={model.logoUrl} alt="" />
            <h1 className="ml-2 text-lg font-display font-semibold">
              {model.name}
            </h1>
          </div>
          <div className="pt-5 px-8">
            <div className="border-b border-b-gray-200 pb-8">
              {/* <img className="w-8" src={model.logoUrl} alt="" /> */}
              <p className="mb-2">{model.description}</p>
              <p className="text-gray-500 text-sm">
                {model?.tips ? model.tips : ""}
              </p>
            </div>

            <div className="pt-8 pb-8 flex w-full flex-1 flex-col">
              <div className="">
                <label
                  htmlFor="comment"
                  className="text-sm font-bold text-gray-700 flex mb-2 items-center"
                >
                  Descrição
                  <QuestionMarkCircleIcon
                    className="ml-1 w-6 h-6 text-gray-300 cursor-pointer"
                    // onMouseEnter={() => {
                    //   alert("mouse");
                    // }}
                    onClick={() => {
                      setOpen(true);
                    }}
                  />
                </label>

                <div className="mt-1">
                  <textarea
                    rows={4}
                    name="comment"
                    id="comment"
                    value={input}
                    disabled={result}
                    onChange={(text) => setInput(text.target.value)}
                    placeholder={model.placeholder}
                    className={classNames(
                      result ? "bg-gray-100" : "bg-white",
                      "block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm",
                    )}
                  />
                  <div className="w-full flex justify-end mt-1">
                    <span className="text-right text-sm text-gray-500">
                      {input.length} caracteres
                    </span>
                  </div>
                </div>
              </div>

              {!result && (
                <div className="flex flex-row text-sm items-center space-x-4">
                  <div>Quantidade:</div>
                  <div className="flex flex-row space-x-2">
                    <button onClick={() => handleAmountChange("minus")}>
                      <MinusIcon
                        className={classNames(
                          "h-5 w-5",
                          resultsAmount === CONTENT_MIN
                            ? "text-gray-400"
                            : "text-indigo-500",
                        )}
                        aria-hidden="true"
                      />
                    </button>
                    <div>{resultsAmount}</div>
                    <button onClick={() => handleAmountChange("plus")}>
                      <PlusIcon
                        className={classNames(
                          "h-5 w-5",
                          resultsAmount === CONTENT_MAX
                            ? "text-gray-400"
                            : "text-indigo-500",
                        )}
                        aria-hidden="true"
                        onClick={() => handleAmountChange("plus")}
                      />
                    </button>
                  </div>
                  <div />
                  <button
                    type="button"
                    onClick={handleOutputs}
                    className="inline-flex items-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                  >
                    Criar conteúdos
                    {loading && <Loading classes="ml-3" />}
                  </button>
                </div>
              )}
            </div>
          </div>

          {result && results?.content && (
            <div className="bg-white mx-auto w-full flex-1 shadow-md rounded-md">
              <div className="border-b border-b-gray-200 py-4 px-4">
                <h2 className="text-xl">Resultados</h2>
              </div>

              <div className="border-b border-b-gray-200 py-4 px-4">
                {/* When the results is text */}
                {results.content.type === "text" &&
                  results.outputs.map((result: IOutputs) => (
                    <div
                      key={result.id}
                      className="border border-gray-200 rounded-md p-4 break-words mb-4"
                    >
                      {result.output}
                      <div className="flex mt-2">
                        <div className="mr-6">
                          <CopyToClipboard
                            text={result.output}
                            onCopy={() => {
                              setOpenNotification({
                                type: "success",
                                title: "Texto copiado",
                                notification:
                                  "Cole o conteúdo copiado na sua plataforma de textos.",
                              });
                            }}
                          >
                            <ClipboardDocumentIcon className="text-gray-500 w-5 h-5 cursor-pointer" />
                          </CopyToClipboard>
                        </div>
                        <div
                          className="mr-2"
                          onClick={() => handleLike("like", result)}
                        >
                          {likedResults.includes(result.id) ? (
                            <HandThumbUpIconSolid className="text-green-700 w-5 h-5 cursor-pointer" />
                          ) : (
                            <HandThumbUpIcon className="text-gray-500 w-5 h-5 cursor-pointer" />
                          )}
                        </div>
                        <div onClick={() => handleLike("dislike", result)}>
                          {dislikedResults.includes(result.id) ? (
                            <HandThumbDownIconSolid className="text-red-700 w-5 h-5 cursor-pointer" />
                          ) : (
                            <HandThumbDownIcon className="text-gray-500 w-5 h-5 cursor-pointer" />
                          )}
                        </div>
                      </div>
                    </div>
                  ))}
                {/* When the results is imagem */}
                {results.content.type === "image" &&
                  results.outputs.map((result: IOutputs) => (
                    <div
                      key={result.id}
                      className="border border-gray-200 rounded-md p-4 break-words mb-4"
                    >
                      <img src={result.output} className="h-96 w-96" />
                      <div className="flex items-center mt-6">
                        <a
                          className="inline-flex mr-4 items-center rounded-md border border-transparent bg-indigo-600 px-3 py-1 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                          href={result.output}
                          target="_blank"
                        >
                          Baixar imagem
                        </a>
                        <div
                          className="mr-2"
                          onClick={() => handleLike("like", result)}
                        >
                          {likedResults.includes(result.id) ? (
                            <HandThumbUpIconSolid className="text-green-700 w-5 h-5 cursor-pointer" />
                          ) : (
                            <HandThumbUpIcon className="text-gray-500 w-5 h-5 cursor-pointer" />
                          )}
                        </div>
                        <div onClick={() => handleLike("dislike", result)}>
                          {dislikedResults.includes(result.id) ? (
                            <HandThumbDownIconSolid className="text-red-700 w-5 h-5 cursor-pointer" />
                          ) : (
                            <HandThumbDownIcon className="text-gray-500 w-5 h-5 cursor-pointer" />
                          )}
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          )}
        </div>
      </div>

      {/* Modal Preciso Ajuda */}
      <Transition.Root show={open} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          initialFocus={cancelButtonRef}
          onClose={setOpen}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                  <div>
                    {/* <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-green-100">
                    <CheckIcon className="h-6 w-6 text-green-600" aria-hidden="true" />
                  </div> */}
                    <div className="">
                      <Dialog.Title
                        as="h3"
                        className="text-lg text-center font-medium leading-6 text-gray-900"
                      >
                        Dicas para melhores resultados
                      </Dialog.Title>
                      <div className="mt-2">
                        <p className="text-sm text-gray-500">
                          <ul className="divide-y divide-gray-200">
                            <li className="flex py-4 justify-start">
                              Forneça mais detalhes para obter resultados mais
                              específicos
                            </li>
                            <li className="flex py-4 justify-start">
                              Tente incluir seu público, espaço do problema, por
                              que é importante e o que o torna único
                            </li>
                            <li className="flex py-4 justify-start">
                              Use 15 palavras ou mais
                            </li>
                          </ul>

                          <div>
                            {model.example && (
                              <button
                                type="button"
                                className="inline-flex w-full justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:col-start-2 sm:text-sm"
                                onClick={() => {
                                  setOpen(false);
                                  setInput(model.example || "");
                                }}
                              >
                                Usar um exemplo
                              </button>
                            )}
                          </div>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
                    <button
                      type="button"
                      className="inline-flex w-full justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:col-start-2 sm:text-sm"
                      onClick={() => {
                        setOpen(false);
                        //@ts-ignore
                        FreshworksWidget("open");
                      }}
                    >
                      Preciso de ajuda
                    </button>
                    <button
                      type="button"
                      className="mt-3 inline-flex w-full justify-center items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:col-start-1 sm:mt-0 sm:text-sm"
                      onClick={() => setOpen(false)}
                      ref={cancelButtonRef}
                    >
                      Voltar
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>

      {/* Modal Mensagem Plano */}
      <CTAModal open={openPlan} close={() => setOpenPlan(false)} />
    </>
  );
}
