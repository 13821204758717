import { FormEvent, useEffect, useState } from "react";
import { useAuth } from "../hooks/auth";
import { api, parseError } from "../services/api";
import { Link, useNavigate } from "react-router-dom";
import { useNotification } from "../hooks/notification";

import LogoImagem from "../assets/logo.png";
import { Loading } from "../components/Loading";

export function Register() {
  const { setOpenNotification } = useNotification();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const navigation = useNavigate();
  const { signIn, account } = useAuth();

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passwordConfirm, setPasswordConfirm] = useState("");

  const [termsCheckbox, setTermsCheckbox] = useState(false);

  useEffect(() => {
    if (account) {
      navigation("/");
    }
  }, []);

  const handleSignUp = async (event: FormEvent) => {
    event.preventDefault();

    if (!name || !email || !password || !passwordConfirm) {
      return;
    }

    if (password !== passwordConfirm) {
      setOpenNotification({
        type: "error",
        title: "Erro ao cadastrar.",
        notification: "As senhas não conferem.",
      });
      return;
    }

    if (!termsCheckbox) {
      setOpenNotification({
        type: "error",
        title: "Erro ao cadastrar.",
        notification: "Você deve aceitar os Termos de Uso para prosseguir.",
      });
      return;
    }

    try {
      setLoading(true);
      setError("");
      await api.post("/accounts/create", {
        name,
        email,
        password,
      });

      setLoading(false);
      setError("");
      navigation("/validate", { state: { email: email } });
    } catch (err) {
      const error = parseError(err);
      setLoading(false);
      setError("");
      setError(error.friend);
      setOpenNotification({
        type: "error",
        title: "Erro no cadastro",
        notification: error.friend,
      });
    }
  };

  return (
    <>
      <div className="flex min-h-full flex-col justify-center py-12 sm:px-6 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-md">
          <img className="mx-auto h-12 w-auto" src={LogoImagem} alt="Copyo" />
          <h2 className="mt-6 text-center text-3xl font-bold tracking-tight text-[#1c033e]">
            Registre uma nova conta
          </h2>
          <p className="mt-2 text-center text-sm text-gray-600">
            Ou{" "}
            <Link
              to="/signin"
              className="font-medium text-[#1c033e] hover:opacity-90"
            >
              volte para o login
            </Link>
          </p>
        </div>

        <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
          <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
            <form className="space-y-6" onSubmit={handleSignUp}>
              <div>
                <label
                  htmlFor="name"
                  className="block text-sm font-medium text-gray-700"
                >
                  Nome
                </label>
                <div className="mt-1">
                  <input
                    id="name"
                    name="name"
                    type="text"
                    required
                    onChange={(input) => setName(input.target.value)}
                    value={name}
                    className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                  />
                </div>
              </div>

              <div>
                <label
                  htmlFor="email"
                  className="block text-sm font-medium text-gray-700"
                >
                  E-mail
                </label>
                <div className="mt-1">
                  <input
                    id="email"
                    name="email"
                    type="email"
                    autoComplete="email"
                    required
                    onChange={(input) => setEmail(input.target.value)}
                    value={email}
                    className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                  />
                </div>
              </div>

              <div>
                <label
                  htmlFor="password"
                  className="block text-sm font-medium text-gray-700"
                >
                  Senha
                </label>
                <div className="mt-1">
                  <input
                    id="password"
                    name="password"
                    type="password"
                    autoComplete="current-password"
                    required
                    onChange={(input) => setPassword(input.target.value)}
                    value={password}
                    className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                  />
                </div>
              </div>

              <div>
                <label
                  htmlFor="password-confirm"
                  className="block text-sm font-medium text-gray-700"
                >
                  Confirme sua Senha
                </label>
                <div className="mt-1">
                  <input
                    id="password-confirm"
                    name="password-confirm"
                    type="password"
                    autoComplete="current-password"
                    required
                    onChange={(input) => setPasswordConfirm(input.target.value)}
                    value={passwordConfirm}
                    className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                  />
                </div>
              </div>

              <div className="text-xs flex items-center">
                <input
                  id="offers"
                  name="terms"
                  type="checkbox"
                  className="h-4 w-4 mr-2 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                  defaultChecked={termsCheckbox}
                  onChange={() => setTermsCheckbox(!termsCheckbox)}
                />
                Eu li e concordo com os
                <a
                  className="text-[#1c033e] mx-1 font-semibold"
                  href="https://www.copyo.ai/termos"
                  target="_blank"
                >
                  Termos de Uso
                </a>{" "}
                da plataforma.
              </div>

              <div>
                <button
                  type="submit"
                  className="flex w-full justify-center rounded-md border border-transparent bg-[#1c033e] py-2 px-4 text-sm font-medium text-white shadow-sm hover:opacity-90 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                >
                  Cadastrar
                  {loading && <Loading classes="ml-5" />}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}
