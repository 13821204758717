import { PlusIcon } from "@heroicons/react/24/outline";
import { useEffect, useState } from "react";
import { useQuery, UseQueryResult } from "react-query";
import { Link } from "react-router-dom";
import Spinner from "../components/Spinner";
import { api } from "../services/api";
import { queryClient } from "../services/queryClient";

interface IContent {
  id: string;
  accountId: string;
  modelId: string;
  input: string;
  words: string;
  totalTokens: number;
  createdAt: Date;
  updatedAt: Date;
  outputs: {
    id: string;
    words: number;
  }[];
  model: {
    id: string;
    name: string;
    description: string;
    category: string;
    logoUrl: string;
  };
}

interface IDataContent {
  total: number;
  page: number;
  totalPage: number;
  contents: IContent[];
}
function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(" ");
}

export function History() {
  const [page, setPage] = useState(0);
  const [totalItens, setTotalItens] = useState(1);

  const { data, isError, isFetched }: UseQueryResult<IDataContent, Error> =
    useQuery(
      ["contents", page],
      async () => {
        const response = await api.get(`/contents?page=${page}`);
        setTotalItens(response.data.total);
        return response.data;
      },
      {
        staleTime: 1000 * 1, //10 s
      },
    );

  useEffect(() => {
    const prefetchNextPage = async () => {
      // The results of this query will be cached like a normal query
      await queryClient.prefetchQuery(["contents", page + 1], async () => {
        const response = await api.get(`/contents?&page=${page + 1}`);
        return response.data;
      });
    };
    prefetchNextPage();
  }, [page]);

  return (
    <>
      <div className="mx-auto py-4 px-4 sm:py-8 sm:px-6 lg:px-8">
        <div className="text-center">
          <p className="mt-1 text-2xl font-display font-bold tracking-tight text-[#1c033e] sm:text-3xl lg:text-4xl">
            Consulte todas as suas solicitações recentes
          </p>
          {/* <p className="mx-auto mt-5 max-w-xl text-lg text-gray-500">
            Comece selecionando o tipo de conteúdo nas opções abaixo
          </p> */}
        </div>
      </div>

      {isError && <div>Error ao Carregar</div>}
      {!isFetched && (
        <div className="flex justify-center mt-12">
          <Spinner />
        </div>
      )}
      <div className="mx-auto py-4 px-4 md:w-9/12">
        {data?.contents && data?.contents.length <= 0 && (
          <div className="text-center">
            <svg
              className="mx-auto h-12 w-12 text-gray-400"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              aria-hidden="true"
            >
              <path
                vectorEffect="non-scaling-stroke"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M9 13h6m-3-3v6m-9 1V7a2 2 0 012-2h6l2 2h6a2 2 0 012 2v8a2 2 0 01-2 2H5a2 2 0 01-2-2z"
              />
            </svg>
            <h3 className="mt-2 text-sm font-medium text-gray-900">
              Nenhuma solicitação
            </h3>
            <p className="mt-1 text-sm text-gray-500">
              Comece criando um novo conteúdo.
            </p>
            <div className="mt-6">
              <Link
                to="/"
                className="inline-flex items-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
              >
                <PlusIcon className="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
                Criar novo conteúdo
              </Link>
            </div>
          </div>
        )}

        <ul className="divide-y divide-gray-200">
          {data?.contents &&
            data?.contents.map((content) => {
              const totalWords =
                content.words +
                content.outputs.reduce(
                  (accumulator, o: any) => accumulator + o.words,
                  0,
                );
              return (
                <li key={content.id} className="py-4">
                  <div className="flex space-x-3">
                    <img
                      className="h-6 w-6"
                      src={content.model.logoUrl}
                      alt=""
                    />
                    <div className="flex-1 space-y-1">
                      <div className="flex items-center justify-between">
                        <h3 className="text-sm font-medium">
                          {content.model.name}
                        </h3>
                        <p className="text-sm text-gray-500">
                          {totalWords} palavras
                        </p>
                      </div>
                      <div className="truncate max-w-sm">
                        <h3 className="text-sm text-gray-500 truncate">
                          {content.input}
                        </h3>
                      </div>
                    </div>
                  </div>
                </li>
              );
            })}
        </ul>
      </div>

      {/* Pagination */}
      <nav
        className="flex items-center mt-6 justify-between border-t border-gray-200 bg-white px-4 py-3 sm:px-6 md:w-9/12 mx-auto"
        aria-label="Pagination"
      >
        <div className="hidden sm:block">
          <p className="text-sm text-gray-700">
            Mostrando <span className="font-medium">{page * 12 + 1}</span> a{" "}
            <span className="font-medium">
              {Math.min((page + 1) * 12, totalItens)}
            </span>{" "}
            de <span className="font-medium">{totalItens}</span> resultados
          </p>
        </div>
        <div className="flex flex-1 justify-between sm:justify-end">
          <button
            disabled={page === 0}
            onClick={() => setPage(page - 1)}
            className={classNames(
              "relative inline-flex items-center rounded-md bg-white px-3 py-2 mr-2 text-sm font-semibold  ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus-visible:outline-offset-0",
              page === 0 ? "text-gray-400" : "text-gray-900",
            )}
          >
            Anterior
          </button>
          <button
            disabled={page === data?.totalPage}
            onClick={() => setPage(page + 1)}
            className={classNames(
              "relative inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold  ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus-visible:outline-offset-0",
              page === data?.totalPage ? "text-gray-400" : "text-gray-900",
            )}
          >
            Próximo
          </button>
        </div>
      </nav>
    </>
  );
}
