import { FormEvent, useEffect, useState } from "react";
import { useAuth } from "../hooks/auth";
import { api, parseError } from "../services/api";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { useNotification } from "../hooks/notification";

import LogoImagem from "../assets/logo.png";
import { Loading } from "../components/Loading";

export function Forgot() {
  const { search } = useLocation();
  const { setOpenNotification } = useNotification();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const navigation = useNavigate();
  const { account } = useAuth();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const [token, setToken] = useState("");

  useEffect(() => {
    if (!search || search === undefined || search === null) {
      setToken("");
    } else if (search) {
      setToken(String(search).replace("?token=", ""));
    }
  }, [search]);

  useEffect(() => {
    if (account) {
      navigation("/");
    }
  });

  const handleSignEmail = async (event: FormEvent) => {
    event.preventDefault();

    if (!email) {
      return;
    }

    try {
      setLoading(true);
      setError("");
      await api.post("/accounts/send/password", {
        email,
        send: true,
      });

      setLoading(false);
      setError("");
      setOpenNotification({
        type: "success",
        title: "Verifique seu e-mail",
        notification:
          "Enviamos um link de redefinição de senha para o seu e-mail.",
      });
      // navigation("/");
    } catch (err) {
      const error = parseError(err);
      setLoading(false);
      setError("");
      setError(error.friend);

      setOpenNotification({
        type: "error",
        title: "Erro ao recuperar senha",
        notification: error.friend,
      });
    }
  };

  const handleResetPassword = async (event: FormEvent) => {
    event.preventDefault();

    if (!password || !confirmPassword) {
      return;
    }

    if (password !== confirmPassword) {
      setOpenNotification({
        type: "error",
        title: "Erro ao mudar a senha.",
        notification: "As senhas não conferem.",
      });
      return;
    }

    try {
      setLoading(true);
      setError("");
      await api.post("/accounts/reset/password", {
        token,
        password,
      });

      setLoading(false);
      setError("");
      setOpenNotification({
        type: "success",
        title: "Senha alterada",
        notification: "Sua senha foi alterada com sucesso.",
      });
      // navigation("/");
    } catch (err) {
      const error = parseError(err);
      setLoading(false);
      setError("");
      setError(error.friend);

      setOpenNotification({
        type: "error",
        title: "Erro ao mudar a senha",
        notification: error.friend,
      });
    }
  };

  return (
    <>
      <div className="flex min-h-full flex-col justify-center py-12 sm:px-6 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-md">
          <img className="mx-auto h-12 w-auto" src={LogoImagem} alt="Copyo" />
          <h2 className="mt-6 text-center text-3xl font-bold tracking-tight text-[#1c033e]">
            Recuperar senha
          </h2>
          <p className="mt-2 text-center text-sm text-gray-600">
            <span className="font-medium text-[#1c033e] hover:opacity-90">
              Solicite o link de redefinição de senha por e-mail
            </span>
          </p>
        </div>

        <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
          <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
            {!token ? (
              <>
                <form className="space-y-6" onSubmit={handleSignEmail}>
                  <div>
                    <label
                      htmlFor="email"
                      className="block text-sm font-medium text-gray-700"
                    >
                      E-mail
                    </label>
                    <div className="mt-1">
                      <input
                        id="email"
                        name="email"
                        type="email"
                        autoComplete="email"
                        required
                        onChange={(input) => setEmail(input.target.value)}
                        value={email}
                        className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                      />
                    </div>
                  </div>

                  <div className="flex items-center justify-between">
                    <div className="flex items-center"></div>
                    <div className="text-sm">
                      <Link
                        to="/signin"
                        className="font-medium text-[#1c033e] hover:opacity-90"
                      >
                        Voltar para o login
                      </Link>
                    </div>
                  </div>

                  <div>
                    <button
                      type="submit"
                      className="flex w-full justify-center rounded-md border border-transparent bg-[#1c033e] py-2 px-4 text-sm font-medium text-white shadow-sm hover:opacity-90 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                    >
                      Enviar
                      {loading && <Loading classes="ml-5" />}
                    </button>
                  </div>
                </form>
              </>
            ) : (
              <>
                <form className="space-y-6" onSubmit={handleResetPassword}>
                  <div>
                    <label
                      htmlFor="password"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Senha
                    </label>
                    <div className="mt-1">
                      <input
                        id="password"
                        name="password"
                        type="password"
                        autoComplete="current-password"
                        required
                        onChange={(input) => setPassword(input.target.value)}
                        value={password}
                        className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                      />
                    </div>
                  </div>

                  <div>
                    <label
                      htmlFor="password-confirm"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Confirmar nova senha
                    </label>
                    <div className="mt-1">
                      <input
                        id="password-confirm"
                        name="password-confirm"
                        type="password"
                        autoComplete="current-password"
                        required
                        onChange={(input) =>
                          setConfirmPassword(input.target.value)
                        }
                        value={confirmPassword}
                        className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                      />
                    </div>
                  </div>

                  <div className="flex items-center justify-between">
                    <div className="flex items-center"></div>
                    <div className="text-sm">
                      <Link
                        to="/signin"
                        className="font-medium text-[#1c033e] hover:opacity-90"
                      >
                        Voltar para o login
                      </Link>
                    </div>
                  </div>

                  <div>
                    <button
                      type="submit"
                      className="flex w-full justify-center rounded-md border border-transparent bg-[#1c033e] py-2 px-4 text-sm font-medium text-white shadow-sm hover:opacity-90 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                    >
                      Enviar
                      {loading && <Loading classes="ml-5" />}
                    </button>
                  </div>
                </form>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
