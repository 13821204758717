import { FormEvent, useEffect, useState } from "react";
import { useAuth } from "../hooks/auth";
import { parseError } from "../services/api";
import { Link, useNavigate } from "react-router-dom";
import { useNotification } from "../hooks/notification";

import LogoImagem from "../assets/logo.png";
import { Loading } from "../components/Loading";

export function SignIn() {
  const { setOpenNotification } = useNotification();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const navigation = useNavigate();
  const { signIn, account } = useAuth();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  useEffect(() => {
    if (account) {
      navigation("/");
    }
  });

  const handleSignIn = async (event: FormEvent) => {
    event.preventDefault();

    if (!email || !password) {
      return;
    }

    try {
      setLoading(true);
      setError("");
      await signIn({
        email,
        password,
      });

      setLoading(false);
      setError("");
      navigation("/");
    } catch (err) {
      const error = parseError(err);
      setLoading(false);

      if (error.code === "account.email-not-confirmed") {
        navigation("/validate", { state: { email: email } });
        return;
      }

      setError("");
      setError(error.friend);

      setOpenNotification({
        type: "error",
        title: "Erro no login",
        notification: error.friend,
      });
    }
  };

  return (
    <>
      <div className="flex min-h-full flex-col justify-center py-12 sm:px-6 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-md">
          <img className="mx-auto h-12 w-auto" src={LogoImagem} alt="Copyo" />
          <h2 className="mt-6 text-center text-3xl font-bold tracking-tight text-[#1c033e]">
            Faça login em sua conta
          </h2>
          {/* <p className="mt-2 text-center text-sm text-gray-600">
            Ou{' '}
            <Link to="/register" className="font-medium text-[#1c033e] hover:opacity-90">
              cria sua conta agora
            </Link>
          </p> */}
        </div>

        <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
          <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
            <form className="space-y-6" onSubmit={handleSignIn}>
              <div>
                <label
                  htmlFor="email"
                  className="block text-sm font-medium text-gray-700"
                >
                  E-mail
                </label>
                <div className="mt-1">
                  <input
                    id="email"
                    name="email"
                    type="email"
                    autoComplete="email"
                    required
                    onChange={(input) => setEmail(input.target.value)}
                    value={email}
                    className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                  />
                </div>
              </div>

              <div>
                <label
                  htmlFor="password"
                  className="block text-sm font-medium text-gray-700"
                >
                  Senha
                </label>
                <div className="mt-1">
                  <input
                    id="password"
                    name="password"
                    type="password"
                    autoComplete="current-password"
                    required
                    onChange={(input) => setPassword(input.target.value)}
                    value={password}
                    className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                  />
                </div>
              </div>

              <div className="flex items-center justify-between">
                {/* <div className="flex items-center">
                  <input
                    id="remember-me"
                    name="remember-me"
                    type="checkbox"
                    className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                  />
                  <label htmlFor="remember-me" className="ml-2 block text-sm text-gray-900">
                    Lembre de mim
                  </label>
                </div> */}

                <div className="text-sm">
                  <Link
                    to="/forgot"
                    className="font-medium text-[#1c033e] hover:opacity-90"
                  >
                    Esqueceu sua senha?
                  </Link>
                </div>
              </div>

              <div>
                <button
                  type="submit"
                  className="flex w-full justify-center rounded-md border border-transparent bg-[#1c033e] py-2 px-4 text-sm font-medium text-white shadow-sm hover:opacity-90 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                >
                  Entrar
                  {loading && <Loading classes="ml-5" />}
                </button>
              </div>
            </form>

            <div className="mt-6">
              <div className="relative">
                <div className="absolute inset-0 flex items-center">
                  <div className="w-full border-t border-gray-300" />
                </div>
                <div className="relative flex justify-center text-sm">
                  <span className="bg-white px-2 text-gray-500">ou</span>
                </div>
              </div>

              <div className="mt-6">
                <div>
                  <Link
                    to="/register"
                    className="flex w-full justify-center rounded-md border border-transparent bg-gray-700 py-2 px-4 text-sm font-medium text-white shadow-sm hover:opacity-90 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                  >
                    Crie uma conta agora
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
