import { Battery0Icon, ShoppingCartIcon } from "@heroicons/react/24/outline";
import { LockClosedIcon } from "@heroicons/react/24/solid";
import { useEffect, useState, useRef, Fragment } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import Search from "../components/Search";
import { useAuth } from "../hooks/auth";
import { api } from "../services/api";
import { getFirstName } from "../services/utils";

import { Dialog, Transition } from "@headlessui/react";
import { useQuery, UseQueryResult } from "react-query";
import Spinner from "../components/Spinner";
import { Loading } from "../components/Loading";
import CTAModal from "../components/CTAModal";

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(" ");
}

const tabs = [
  { name: "Todos", href: "/" },
  { name: "Social Media", href: "/?category=social%20media" },
  { name: "Ads", href: "/?category=ads" },
  { name: "SEO", href: "/?category=seo" },
  { name: "Blog", href: "/?category=blog" },
  { name: "Conteúdo", href: "/?category=conteúdo" },
  { name: "E-mail", href: "/?category=e-mail" },
  { name: "Imagem", href: "/?category=imagem" },
];

interface IModel {
  id: string;
  name: string;
  description: string;
  category: string;
  logoUrl: string;
  available: boolean;
  plans: string[];
}

export function Dashboard() {
  const { account } = useAuth();
  const [searchParams] = useSearchParams();

  const [filteredModels, setFilteredModels] = useState<IModel[]>([]);
  const [inputSearch, setInputSearch] = useState("");
  const navigation = useNavigate();

  const [open, setOpen] = useState(false);

  const {
    data: models,
    isError,
    isFetched,
  }: UseQueryResult<IModel[], Error> = useQuery(
    "models",
    async () => {
      const response = await api.get("/models");
      return response.data.models;
    },
    {
      staleTime: 1000 * 60 * 10, //10 min
    },
  );

  useEffect(() => {
    if (!models) return;

    if (
      account &&
      account.wordsUsage >= account.plan?.wordsLimit &&
      account.plan?.name === "Free"
    ) {
      setOpen(true);
    }

    // if it's null, we want to return all of the categories
    const selectedCategory = searchParams.get("category");
    const input = inputSearch.toLocaleLowerCase();

    const filters = models.filter((model) => {
      const name = model.name.toLocaleLowerCase();
      const categories = model.category
        .split(", ")
        .map((i: any) => i.toLowerCase());

      return (
        name.includes(input) &&
        (categories.includes(selectedCategory) || !selectedCategory)
      );
    });
    setFilteredModels(filters);
  }, [inputSearch, models, searchParams.get("category")]);

  return (
    <>
      <div className="mx-auto py-4 px-4 sm:py-8 sm:px-6 lg:px-8">
        <div className="text-center">
          <p className="mt-1 text-2xl font-bold font-display tracking-tight text-[#1c033e] sm:text-3xl lg:text-4xl">
            {getFirstName(account.name)}, o que vamos criar hoje? ✍️
          </p>
          <p className="mx-auto mt-5 max-w-xl text-lg text-gray-600">
            Selecione o tipo de conteúdo nas opções abaixo
          </p>
        </div>
      </div>

      <Search value={inputSearch} onChange={setInputSearch} />

      {/* TABS */}

      <div className="mb-10 mt-16">
        <div className="sm:hidden">
          <label htmlFor="tabs" className="sr-only">
            Selectione uma categoria
          </label>

          <select
            id="tabs"
            name="tabs"
            className="block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
            defaultValue={"Todos"}
            onChange={(e) => navigation(e.target.value)}
          >
            {tabs.map((tab) => {
              return (
                <option key={tab.name} value={tab.href}>
                  {tab.name}
                </option>
              );
            })}
          </select>
        </div>
        <div className="hidden sm:block">
          <div className="border-b border-gray-200">
            <nav className="-mb-px flex space-x-8" aria-label="Tabs">
              {tabs.map((tab) => {
                const selectedCategory = searchParams.get("category");
                let isCurrent = false;
                if (tab.name.toLowerCase() === "todos" && !selectedCategory)
                  isCurrent = true;
                if (tab.name.toLowerCase() === selectedCategory)
                  isCurrent = true;
                return (
                  <a
                    key={tab.name}
                    href={tab.href}
                    className={classNames(
                      isCurrent
                        ? "border-indigo-500 text-indigo-600"
                        : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300",
                      "whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm",
                    )}
                    aria-current={isCurrent ? "page" : undefined}
                  >
                    {tab.name}
                  </a>
                );
              })}
            </nav>
          </div>
        </div>
      </div>

      {isError && <div>Error ao Carregar</div>}
      {!isFetched && (
        <div className="flex justify-center mt-12">
          <Spinner />
        </div>
      )}
      {isFetched && (
        <ul className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3">
          {filteredModels.map((model) => (
            <li
              key={model.id}
              className="cursor-pointer col-span-1 overflow-hidden divide-y divide-gray-200 rounded-lg bg-white shadow"
            >
              {model.available ? (
                <Link to={`/create/${model.id}`}>
                  <div className="flex w-full items-center justify-between space-x-4 p-4">
                    <img
                      className="h-8 w-8 flex-shrink-0"
                      src={model.logoUrl}
                      alt=""
                    />
                    <div className="flex-1 truncate">
                      <div className="flex items-center space-x-3">
                        <h3 className="truncate text-sm font-display font-medium text-gray-900">
                          {model.name}
                        </h3>
                      </div>
                      <p className="mt-1 truncate text-sm text-gray-500">
                        {model.description}
                      </p>
                    </div>
                  </div>
                  <div></div>
                </Link>
              ) : (
                <div className="flex w-full items-center justify-center group relative">
                  <div className="flex w-full items-center justify-between space-x-4 p-4">
                    <img
                      className="h-8 w-8 flex-shrink-0"
                      src={model.logoUrl}
                      alt=""
                    />
                    <div className="flex-1 truncate">
                      <div className="flex items-center justify-between space-x-3">
                        <h3 className="truncate text-sm font-display font-medium text-gray-900 opacity-60">
                          {model.name}
                        </h3>
                        <LockClosedIcon
                          className={classNames(
                            "h-4 w-4",
                            model.plans[0] === "Personal"
                              ? "text-yellow-500"
                              : "text-red-500",
                          )}
                        />
                      </div>
                      <p className="mt-1 truncate text-sm text-gray-500 opacity-80">
                        {model.description}
                      </p>
                    </div>
                  </div>
                  <span className="absolute bottom-1 scale-0 delay-200 transition-all rounded bg-gray-800 p-2 text-sm text-white group-hover:scale-100">
                    Disponível no plano {model.plans[0]} ou superior
                  </span>
                </div>
              )}
            </li>
          ))}
        </ul>
      )}

      {/* Modal */}
      <CTAModal open={open} close={() => setOpen(false)} />
    </>
  );
}
