import { Fragment } from "react";
import { Link } from "react-router-dom";

import LogoImage from "../assets/logo.png";

import { Popover, Transition } from "@headlessui/react";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import MyAccount from "./MyAccounts";

// function classNames(...classes: string[]): string {
//   return classes.filter(Boolean).join(' ')
// }

function Header() {
  // const [isScrolled, setIsScrolled] = useState(false);
  // const handleScroll = () => {
  //   const position = window.pageYOffset;
  //   setIsScrolled(position > 25);
  // };
  // useEffect(() => {
  //   window.addEventListener("scroll", handleScroll);

  //   return () => {
  //     window.removeEventListener("scroll", handleScroll);
  //   };
  // }, []);

  return (
    <Popover className="relative bg-[#fff]">
      <div className="mx-auto max-w-7xl">
        <div className="flex items-center justify-between border-b-1 shadow-sm border-gray-100 py-3 px-4 sm:px-6 md:justify-start md:space-x-10">
          <div className="flex justify-start lg:w-0 lg:flex-1">
            <Link to="/">
              <span className="sr-only">Copyo</span>
              <img className="w-auto h-5 sm:h-9" src={LogoImage} alt="Copyo" />
            </Link>
          </div>
          <div className="-my-2 -mr-2 md:hidden">
            <Popover.Button className="inline-flex items-center justify-center rounded-md bg-white p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
              <span className="sr-only">Open menu</span>
              <Bars3Icon className="h-6 w-6" aria-hidden="true" />
            </Popover.Button>
          </div>
          <Popover.Group
            as="nav"
            className="hidden space-x-10 md:flex font-display font-regular"
          >
            <Link
              to="/"
              className="text-base text-gray-600 hover:text-gray-900"
            >
              Início
            </Link>
            <Link
              to="/projects"
              className="text-base text-gray-600 hover:text-gray-900"
            >
              Meus Conteúdos
            </Link>
            <Link
              to="/history"
              className="text-base text-gray-600 hover:text-gray-900"
            >
              Histórico
            </Link>

            <Link
              to="/price"
              className="text-base text-gray-600 hover:text-gray-900"
            >
              Planos
            </Link>
          </Popover.Group>
          <div className="hidden items-center justify-end md:flex md:flex-1 lg:w-0">
            <MyAccount />
          </div>
        </div>
      </div>

      <Transition
        as={Fragment}
        enter="duration-200 ease-out"
        enterFrom="opacity-0 scale-95"
        enterTo="opacity-100 scale-100"
        leave="duration-100 ease-in"
        leaveFrom="opacity-100 scale-100"
        leaveTo="opacity-0 scale-95"
      >
        <Popover.Panel
          focus
          className="z-20 absolute inset-x-0 top-0 origin-top-right transform p-2 transition md:hidden"
        >
          <div className="divide-y-2 divide-gray-50 rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5">
            <div className="px-5 pt-5 pb-6">
              <div className="flex items-center justify-between">
                <div>
                  <img className="h-8 w-auto" src={LogoImage} alt="Copyo" />
                </div>
                <div className="-mr-2">
                  <Popover.Button className="inline-flex items-center justify-center rounded-md bg-white p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                    <span className="sr-only">Close menu</span>
                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                  </Popover.Button>
                </div>
              </div>
            </div>
            <div className="space-y-6 py-6 px-5 font-display">
              <div className="grid grid-cols-2 gap-y-4 gap-x-8">
                <Link
                  to="/"
                  className="text-base font-medium text-gray-900 hover:text-gray-700"
                >
                  Início
                </Link>

                <Link
                  to="/projects"
                  className="text-base font-medium text-gray-900 hover:text-gray-700"
                >
                  Meus Conteúdos
                </Link>

                <Link
                  to="/history"
                  className="text-base font-medium text-gray-900 hover:text-gray-700"
                >
                  Histórico
                </Link>

                <Link
                  to="/price"
                  className="text-base font-medium text-gray-900 hover:text-gray-700"
                >
                  Planos
                </Link>
              </div>
              <div className="flex justify-end">
                <MyAccount />
              </div>
            </div>
          </div>
        </Popover.Panel>
      </Transition>
    </Popover>
  );
}

export default Header;
