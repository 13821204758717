export function getFirstName(fullName: string): string {
  if (fullName) {
    const name = fullName.split(' ')?.[0];

  if (name) {
    return name;
  }

    return fullName;
  }
  return fullName;
}

export function getMenuName(fullName: string): string {
  if (fullName) {
    const name = fullName.split(' ')?.[0];
    const secondName = fullName.split(' ')?.[1];

    if (name && secondName) {
      return `${name} ${secondName}`;
    }

    return fullName;
  }

  return fullName;
}