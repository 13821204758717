import { FormEvent, useEffect, useState } from "react";
import { useAuth } from "../hooks/auth";
import { api, parseError } from "../services/api";
import {
  Link,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { useNotification } from "../hooks/notification";

import { ArrowUturnLeftIcon } from "@heroicons/react/24/solid";

import LogoImagem from "../assets/logo.png";
import { Loading } from "../components/Loading";

export function Validate() {
  const { setOpenNotification } = useNotification();
  const [loading, setLoading] = useState(true);
  const [loadingValidate, setLoadingValidate] = useState(false);
  const [codeInput, setCodeInput] = useState("");
  const navigation = useNavigate();
  const { account, signInWithToken } = useAuth();

  const { state } = useLocation();

  useEffect(() => {
    import("react-facebook-pixel")
      .then((x) => x.default)
      .then((ReactPixel) => {
        ReactPixel.init("511679694290359");
        ReactPixel.track("CompleteRegistration");
      });
  }, []);

  useEffect(() => {
    if (account) {
      navigation("/");
    }
    if (!state?.email) navigation("/signin");

    setLoading(false);
  }, []);

  const handleValidate = async (event: FormEvent) => {
    event.preventDefault();
    if (codeInput.length < 4) {
      setOpenNotification({
        type: "error",
        title: "Erro ao validar",
        notification: "Coloque o código recebido para realizar a validação",
      });
      return;
    }

    setLoadingValidate(true);
    try {
      const { data } = await api.post("/accounts/confirmation/email", {
        email: state.email,
        confirmationCode: codeInput,
      });
      signInWithToken(data.token);
      setOpenNotification({
        type: "success",
        title: "Validação concluída",
        notification: "A validação por email foi concluída com sucesso.",
      });
      setLoading(false);
      navigation("/");
      setLoadingValidate(true);
    } catch (err) {
      const error = parseError(err);
      setLoading(false);
      setOpenNotification({
        type: "error",
        title: "Erro ao validar",
        notification: error.friend,
      });
    }

    setLoadingValidate(false);
  };

  const handleResend = async (event: FormEvent) => {
    try {
      const { data } = await api.post("/accounts/confirmation/email/resend", {
        email: state.email,
      });

      setLoading(false);
      setOpenNotification({
        type: "success",
        title: "Novo email",
        notification: "Um email com um novo código foi enviado.",
      });
    } catch (err) {
      const error = parseError(err);
      setLoading(false);
      setOpenNotification({
        type: "error",
        title: "Erro",
        notification: error.friend,
      });
    }
  };

  if (loading) return <Loading />;

  return (
    <>
      <div className="flex min-h-full flex-col justify-center py-12 sm:px-6 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-md">
          <img className="mx-auto h-12 w-auto" src={LogoImagem} alt="Copyo" />
          <h2 className="mt-6 text-center text-3xl font-bold tracking-tight text-[#1c033e]">
            Valide seu email
          </h2>
          <p className="mt-2 text-center text-sm text-gray-600">
            <span className="font-medium text-[#1c033e] hover:opacity-90">
              Enviamos um email com código de confirmação para{" "}
              <span className="inline-block bold">{state.email}</span>
            </span>
          </p>
        </div>

        <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
          <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
            <form onSubmit={handleValidate} className="space-y-6">
              <div>
                <label
                  htmlFor="name"
                  className="block text-sm font-medium text-gray-700"
                >
                  Código recebido
                </label>
                <div className="mt-1">
                  <input
                    id="name"
                    name="name"
                    type="text"
                    required
                    onChange={(input) => setCodeInput(input.target.value)}
                    value={codeInput}
                    className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                  />
                </div>
              </div>
              <div className="flex items-center justify-between">
                <div className="flex items-center"></div>
                <div className="text-sm">
                  <Link
                    to="/signin"
                    className="font-medium text-[#1c033e] hover:opacity-90"
                  >
                    Voltar para o login
                  </Link>
                </div>
              </div>
              <div>
                <button
                  type="submit"
                  disabled={loadingValidate}
                  className="flex w-full justify-center rounded-md border border-transparent bg-[#1c033e] py-2 px-4 text-sm font-medium text-white shadow-sm hover:opacity-90 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                >
                  Validar código
                  {loading && <Loading classes="ml-5" />}
                </button>
              </div>
            </form>
          </div>
          <div className="w-full text-center mt-12">
            <a
              className="text-[#1c033e] text-sm py-2 font-semibold hover:cursor-pointer"
              onClick={handleResend}
            >
              Clique aqui para reenviar o e-mail com código.
            </a>
          </div>
        </div>
      </div>
    </>
  );
}
