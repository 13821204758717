import Routes from "./routes";
import { Hooks } from "./hooks";
import { Notification } from "./components/Notification";
import { QueryClientProvider } from "react-query";
import { queryClient } from "./services/queryClient";

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <Hooks>
        <Routes />
        <Notification />
      </Hooks>
    </QueryClientProvider>
  );
}

export default App;
