import { Dialog, Transition } from "@headlessui/react";
import { Battery0Icon } from "@heroicons/react/24/outline";
import { Fragment, useState } from "react";
import { useAuth } from "../hooks/auth";
import { api } from "../services/api";
import { Loading } from "./Loading";

export default function CTAModal({
  open,
  close,
}: {
  open: boolean;
  close: () => void;
}) {
  const { account } = useAuth();
  const [priceLoading, setPriceLoading] = useState(false);
  async function handleClickPrice(priceId: string) {
    setPriceLoading(true);
    try {
      if (account.plan.name === "Free") {
        const { data } = await api.post("/stripe/create-checkout-session", {
          priceId,
        });
        setPriceLoading(false);
        if (data && data.url) {
          window.location.href = data.url;
        }
      } else {
        const { data } = await api.post("/stripe/create-portal-session");

        if (data && data.url) {
          window.location.href = data.url;
        }
      }
    } catch (err) {
      setPriceLoading(false);
    }
  }
  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={close}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 bottom-20 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                <div>
                  <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-green-100">
                    <Battery0Icon
                      className="h-6 w-6 text-green-600"
                      aria-hidden="true"
                    />
                  </div>
                  <div className="mt-3 text-center sm:mt-5">
                    <Dialog.Title
                      as="h3"
                      className="text-lg font-medium leading-6 text-gray-900"
                    >
                      Você atingiu seu limite de palavras do plano
                    </Dialog.Title>
                    {/* Mensagem padrão */}
                    <div className="mt-2">
                      <p className="text-sm mb-6 text-gray-600 padding-">
                        Para continuar utilizando a Copyo, assine um de nossos
                        planos.
                      </p>
                    </div>

                    {/* Campanha de Cupons */}
                    {/* <div className="mt-2">
                        <p className="text-sm mb-6 text-gray-600">
                          Para continuar utilizando a Copyo, você pode
                          aproveitar um de nossos cupons de desconto, válidos
                          por 3 meses:
                        </p>
                        <p className="text-sm mb-2 text-gray-600">
                          25% de desconto no plano Personal: Cupom{" "}
                          <span className="font-bold">PERSONAL25</span>
                        </p>
                        <p className="text-sm text-gray-600">
                          50% de desconto no plano Pro: Cupom{" "}
                          <span className="font-bold">PRO50</span>
                        </p>
                      </div> */}
                  </div>
                </div>
                {/* Botões normais */}
                {/*
                  <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
                    <Link
                      to="/price"
                      className="inline-flex w-full justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:col-start-2 sm:text-sm"
                      // onClick={() => {
                      //   setOpen(false);
                      // }}
                    >
                      Explorar Planos
                    </Link>
                    <button
                      type="button"
                      className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:col-start-1 sm:mt-0 sm:text-sm"
                      onClick={() => setOpen(false)}
                      ref={cancelButtonRef}
                    >
                      Fechar
                    </button> 
                    
                  </div>
                    */}

                {priceLoading && (
                  <>
                    <div className="mt-3 text-center sm:mt-5">
                      {/* <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900">
                      Aguarde
                    </Dialog.Title> */}
                      <div className="mt-2 flex justify-center items-center">
                        <p className="text-lg font-medium leading-6 text-gray-500">
                          Carregando compra segura
                        </p>
                        <Loading classes="ml-3" />
                      </div>
                    </div>
                  </>
                )}
                {!priceLoading && (
                  <>
                    <button
                      type="button"
                      className="mb-3 w-full justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:col-start-2 sm:text-sm"
                      onClick={() =>
                        handleClickPrice("price_1MbAfJAchoCNzDWqoKd9RuFo")
                      }
                    >
                      Plano PRO (todos os modelos)
                      <span className="inline-block ml-0.5">
                        - R$89,00 - 50.000 palavras
                      </span>
                    </button>

                    <button
                      type="button"
                      className="mt-3 mb-3 w-full justify-center rounded-md border border-indigo-100 bg-indigo-100 px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-indigo-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:col-start-1 sm:mt-0 sm:text-sm"
                      onClick={() =>
                        handleClickPrice("price_1MbAebAchoCNzDWqYIzdjTOC")
                      }
                    >
                      Plano PERSONAL (20+ modelos)
                      <span className="inline-block ml-0.5">
                        - R$49,00 - 20.000 palavras
                      </span>
                    </button>
                    <button
                      type="button"
                      className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:col-start-1 sm:mt-0 sm:text-sm"
                      onClick={() => close()}
                    >
                      Agora não
                    </button>
                  </>
                )}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
