// import {  useEffect, useState } from "react";
// import { Link } from "react-router-dom";
// import { api } from "../services/api";

import {
  // BellIcon,
  // CogIcon,
  CreditCardIcon,
  // KeyIcon,
  BookOpenIcon,
  UserCircleIcon,
} from "@heroicons/react/24/outline";
import { FormEvent, useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { useAuth } from "../hooks/auth";
import { useNotification } from "../hooks/notification";
import { api, parseError } from "../services/api";

function classNames(...classes: string[]): string {
  return classes.filter(Boolean).join(" ");
}

export function Account() {
  const { account } = useAuth();
  const { setOpenNotification } = useNotification();
  const { search } = useLocation();
  const [type, setType] = useState("account");

  const [currentPassword, setCurrentPassword] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const [subNavigation, setSubNavigation] = useState([
    {
      name: "Minha conta",
      href: "account",
      icon: UserCircleIcon,
      current: true,
    },
    { name: "Meu plano", href: "plan", icon: BookOpenIcon, current: false },
    { name: "Faturas", href: "billings", icon: CreditCardIcon, current: false },
  ]);

  const [percent, setPercent] = useState("0%");

  const [fatura, setFatura] = useState<Boolean | string>(false);

  useEffect(() => {
    api
      .get("/accounts/billings")
      .then(({ data }) => {
        setFatura(data.url);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    const total = account.plan.wordsLimit;
    const usage = account.wordsUsage;
    const percentUsage = (usage / total) * 100;

    if (percentUsage > 100) {
      setPercent(`100%`);
    } else {
      setPercent(`${percentUsage}%`);
    }
  }, [account.wordsUsage, account.plan.wordsLimit]);

  useEffect(() => {
    if (!search || search === undefined || search === null) {
      setType("account");
    } else if (search) {
      setType(String(search).replace("?type=", ""));
    }
  }, [search]);

  useEffect(() => {
    setSubNavigation(
      subNavigation.map((navigation) => {
        if (navigation.href === type) {
          return {
            ...navigation,
            current: true,
          };
        }

        return {
          ...navigation,
          current: false,
        };
      }),
    );
  }, [type]);

  async function handlePassword(event: FormEvent) {
    event.preventDefault();

    if (!password || !confirmPassword || !currentPassword) {
      return;
    }

    if (password !== confirmPassword) {
      setOpenNotification({
        type: "error",
        title: "Erro ao mudar a senha.",
        notification: "As senhas não conferem.",
      });
      return;
    }

    try {
      await api.post("/accounts/session", {
        email: account.email,
        password: currentPassword,
      });

      const {
        data: { id },
      } = await api.post("/accounts/send/password", {
        email: account.email,
        send: false,
      });

      await api.post("/accounts/reset/password", {
        token: id,
        password: password,
      });

      setOpenNotification({
        type: "success",
        title: "Senha alterada.",
        notification: "Senha alterada com sucesso.",
      });
    } catch (err) {
      const error = parseError(err);

      setOpenNotification({
        type: "error",
        title: "Erro ao mudar a senha",
        notification: error.friend,
      });
    }
  }

  return (
    <>
      {/* 
      <div className="mx-auto py-4 px-4 sm:py-8 sm:px-6 lg:px-8">
        <div className="text-center">
          <p className="mt-1 text-2xl font-bold tracking-tight text-[#1c033e] sm:text-3xl lg:text-4xl">
            Minha conta
          </p>
          <p className="mx-auto mt-5 max-w-xl text-lg text-gray-500">
            Comece selecionando o tipo de conteúdo nas opções abaixo
          </p>
        </div>
      </div>
       */}

      <div className="mx-auto py-4 px-4 md:w-12/12">
        <main className="relative">
          <div className="mx-auto max-w-screen-xl px-4 pb-6 sm:px-6 lg:px-8 lg:pb-16">
            <div className="overflow-hidden rounded-lg bg-white shadow">
              <div className="divide-y divide-gray-200 lg:grid lg:grid-cols-12 lg:divide-y-0 lg:divide-x">
                <aside className="py-6 lg:col-span-3">
                  <nav className="space-y-1">
                    {subNavigation.map((item) => (
                      <span
                        onClick={() => setType(item.href)}
                        key={item.name}
                        className={classNames(
                          item.current
                            ? "bg-teal-50 border-teal-500 text-teal-700 hover:bg-teal-50 hover:text-teal-700"
                            : "border-transparent text-gray-900 hover:bg-gray-50 hover:text-gray-900",
                          "group border-l-4 px-3 py-2 flex items-center text-sm font-medium cursor-pointer",
                        )}
                        aria-current={item.current ? "page" : undefined}
                      >
                        <item.icon
                          className={classNames(
                            item.current
                              ? "text-teal-500 group-hover:text-teal-500"
                              : "text-gray-400 group-hover:text-gray-500",
                            "flex-shrink-0 -ml-1 mr-3 h-6 w-6",
                          )}
                          aria-hidden="true"
                        />
                        <span className="truncate">{item.name}</span>
                      </span>
                    ))}
                  </nav>
                </aside>

                {type === "account" && (
                  <form
                    onSubmit={handlePassword}
                    className="divide-y divide-gray-200 lg:col-span-9"
                  >
                    <div className="py-6 px-4 sm:p-6 lg:pb-8">
                      <div>
                        <h2 className="text-lg font-display font-medium leading-6 text-gray-900">
                          Minha Conta
                        </h2>
                        <p className="mt-3 text-sm text-gray-500">
                          <div className="rounded-md border border-gray-300 px-3 py-2 shadow-sm focus-within:border-indigo-600 focus-within:ring-1 focus-within:ring-indigo-600 bg-gray-100">
                            <label
                              htmlFor="name"
                              className="block text-xs font-medium text-gray-900"
                            >
                              Nome
                            </label>
                            <input
                              type="text"
                              name="name"
                              id="name"
                              disabled
                              value={account.name}
                              className="block w-full border-0 p-0 bg-gray-100 text-gray-900 placeholder-gray-500 focus:ring-0 sm:text-sm"
                            />
                          </div>

                          <div className="mt-4 rounded-md border border-gray-300 px-3 py-2 shadow-sm focus-within:border-indigo-600 focus-within:ring-1 focus-within:ring-indigo-600 bg-gray-100">
                            <label
                              htmlFor="name"
                              className="block text-xs font-medium text-gray-900"
                            >
                              E-mail
                            </label>
                            <input
                              type="text"
                              name="name"
                              id="name"
                              disabled
                              value={account.email}
                              className="block w-full border-0 p-0 bg-gray-100 text-gray-900 placeholder-gray-500 focus:ring-0 sm:text-sm"
                            />
                          </div>

                          <h2 className="mt-4 text-lg font-medium leading-6 text-gray-900">
                            Trocar senha
                          </h2>

                          <div className="w-full md:w-7/12">
                            <div className="mt-4 rounded-md border border-gray-300 px-3 py-2 shadow-sm focus-within:border-indigo-600 focus-within:ring-1 focus-within:ring-indigo-600">
                              <label
                                htmlFor="currentPassword"
                                className="block text-xs font-medium text-gray-900"
                              >
                                Senha atual
                              </label>
                              <input
                                type="password"
                                name="currentPassword"
                                id="currentPassword"
                                value={currentPassword}
                                onChange={(input) =>
                                  setCurrentPassword(input.target.value)
                                }
                                className="block w-full border-0 p-0 text-gray-900 placeholder-gray-500 focus:ring-0 sm:text-sm"
                              />
                            </div>
                          </div>

                          <div className="w-full md:w-7/12">
                            <div className="mt-4 rounded-md border border-gray-300 px-3 py-2 shadow-sm focus-within:border-indigo-600 focus-within:ring-1 focus-within:ring-indigo-600">
                              <label
                                htmlFor="password"
                                className="block text-xs font-medium text-gray-900"
                              >
                                Nova senha
                              </label>
                              <input
                                type="password"
                                name="password"
                                id="password"
                                value={password}
                                onChange={(input) =>
                                  setPassword(input.target.value)
                                }
                                className="block w-full border-0 p-0 text-gray-900 placeholder-gray-500 focus:ring-0 sm:text-sm"
                              />
                            </div>
                          </div>

                          <div className="w-full md:w-7/12">
                            <div className="mt-4 rounded-md border border-gray-300 px-3 py-2 shadow-sm focus-within:border-indigo-600 focus-within:ring-1 focus-within:ring-indigo-600">
                              <label
                                htmlFor="confirmPassword"
                                className="block text-xs font-medium text-gray-900"
                              >
                                Confirmar nova senha
                              </label>
                              <input
                                type="password"
                                name="confirmPassword"
                                id="confirmPassword"
                                value={confirmPassword}
                                onChange={(input) =>
                                  setConfirmPassword(input.target.value)
                                }
                                className="block w-full border-0 p-0 text-gray-900 placeholder-gray-500 focus:ring-0 sm:text-sm"
                              />
                            </div>
                          </div>
                          <div className="w-full md:w-7/12">
                            <button
                              type="submit"
                              className="mt-4 inline-flex items-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                            >
                              Alterar senha
                            </button>
                          </div>
                        </p>
                      </div>
                    </div>
                  </form>
                )}

                {type === "plan" && (
                  <form
                    className="divide-y divide-gray-200 lg:col-span-9"
                    action="#"
                    method="POST"
                  >
                    <div className="py-6 px-4 sm:p-6 lg:pb-8">
                      <div>
                        <h2 className="text-lg font-display font-medium leading-6 text-gray-900">
                          Meu Plano
                        </h2>
                        <p className="mt-1 text-sm text-gray-500">
                          <div className="px-4 py-2">
                            <span className="flex items-center mb-2 text-sm leading-6 font-medium">
                              {account.plan.name}
                            </span>
                            <div className="bg-gray-200 w-full h-2 rounded-sm mt-2 mb-1">
                              <div
                                className="bg-gray-500 h-2 rounded-sm"
                                style={{ width: percent }}
                              ></div>
                            </div>
                            <span className="text-xs mb-3 block">
                              {account.wordsUsage} de {account.plan.wordsLimit}{" "}
                              palavras usadas.
                            </span>
                            {account.plan.name === "Free" && (
                              <div>
                                <Link to="/price">
                                  <span className="inline-flex items-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                                    Explorar planos
                                  </span>
                                </Link>
                              </div>
                            )}
                            {account.plan.name !== "Free" && (
                              <a href={`${fatura}`} rel="noreferrer">
                                <span className="inline-flex items-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                                  Gerenciar plano
                                </span>
                              </a>
                            )}
                          </div>
                        </p>
                      </div>
                    </div>
                  </form>
                )}

                {type === "billings" && (
                  <form
                    className="divide-y divide-gray-200 lg:col-span-9"
                    action="#"
                    method="POST"
                  >
                    <div className="py-6 px-4 sm:p-6 lg:pb-8">
                      <div>
                        <h2 className="text-lg font-display font-medium leading-6 text-gray-900">
                          Faturas
                        </h2>
                        <p className="mt-1 text-sm text-gray-500">
                          {fatura ? (
                            <a href={`${fatura}`} rel="noreferrer">
                              <span className="inline-flex items-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                                Ir para minhas faturas
                              </span>
                            </a>
                          ) : (
                            <span>
                              <span className="inline-flex items-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                                Nenhuma fatura.
                              </span>
                            </span>
                          )}
                        </p>
                      </div>
                    </div>
                  </form>
                )}
              </div>
            </div>
          </div>
        </main>
      </div>
    </>
  );
}
